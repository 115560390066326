/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { observer } from "mobx-react";

import { MenuComponent } from "../../../../../assets/ts/components";
import { UserDocument } from "../../../../../app/models/UserDocument";
import stores from "../../../../../app/stores";

export const DocumentActionModal: React.FC<{
  userDocument: UserDocument;
  onRename: () => void;
}> = observer(({ userDocument, onRename }) => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const handleDeleteDocument = () => {
    stores.userDocumentStore.deleteUserDocument(userDocument.id);
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
          Actions
        </div>
      </div>
      <div className="px-3 py-3">
        <a
          onClick={onRename}
          className="px-3 d-flex justify-content-between align-items-center text-warning"
        >
          Rename
          <i className="fas fa-edit text-warning"></i>
        </a>
      </div>
      <div className="px-3 py-3">
        <a
          onClick={handleDeleteDocument}
          className="px-3 d-flex justify-content-between align-items-center text-danger"
        >
          Delete
          <i className="fas fa-trash-alt text-danger"></i>
        </a>
      </div>
    </div>
  );
});
