/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserInvite } from "../../app/models/CompanyUserInvite";

export default async function updateCompanyUserInvite(
  companyUserInvite: CompanyUserInvite
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateCompanyUserInvite(
        id: ${companyUserInvite.id}
        name: "${companyUserInvite.name}"
        email: "${companyUserInvite.email}"
        company_id: ${companyUserInvite.company_id}
        role_id: ${companyUserInvite.role_id}
        status: "${companyUserInvite.status}"
        invited_at: ${companyUserInvite.invited_at}
      ) 
    }
  `;

  const updateCompanyUserInviteMutationResult = await client.mutate({
    mutation,
  });

  const result = get(
    updateCompanyUserInviteMutationResult,
    "data.updateCompanyUserInvite",
    null
  );

  return result !== null && result[0] > 0;
}
