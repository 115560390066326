/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyApp } from "../../app/models/CompanyApp";

export default async function updateCompanyApp(
  companyApp: CompanyApp
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const sourceIds = companyApp.source_ids
    ? encodeURIComponent(JSON.stringify(companyApp.source_ids))
    : "";

  const mutation = gql`
    mutation {
      updateCompanyApp(
        id: ${companyApp.id}
        app_id: ${companyApp.app_id}
        company_id: ${companyApp.company_id}
        credentials: "${companyApp.credentials}"
        status: "${companyApp.status}"
        modified_at: ${companyApp.modified_at}
        last_sync_at: ${companyApp.last_sync_at}
        source_ids: "${sourceIds}"
      ) 
    }
  `;

  const updateCompanyAppMutationResult = await client.mutate({ mutation });

  const result = get(
    updateCompanyAppMutationResult,
    "data.updateCompanyApp",
    null
  );

  return result !== null && result[0] > 0;
}
