import React, { useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { observer } from "mobx-react";

const CompanyDetailsForm = observer(() => {
  const formik = useFormikContext<any>();
  const [preview, setPreview] = useState<string>("");

  if (!formik) {
    return null;
  }

  const { setFieldValue } = formik;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      setFieldValue("logo", file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileRemove = () => {
    setFieldValue("logo", null);
    setPreview("");
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="py-10" onKeyDown={handleKeyDown}>
      <div className="text-center mb-10">
        <h1 className="mb-3">Enter Company Details</h1>
        <div className="text-muted fw-bold fs-5">
          Provide the necessary information about your company to get started.
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="d-flex align-items-center form-label mb-3 required">
          Specify Team Size
        </label>
        <div className="row mb-2" data-kt-buttons="true">
          {["1-1", "2-10", "10-50", "50+"].map((size, index) => (
            <div className="col" key={index}>
              <Field
                type="radio"
                className="btn-check"
                name="teamSize"
                value={size}
                id={`kt_team_size_select_${index + 1}`}
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                htmlFor={`kt_team_size_select_${index + 1}`}
              >
                <span className="fw-bolder fs-3">{size}</span>
              </label>
            </div>
          ))}
        </div>
        <div className="form-text">
          Customers will see this shortened version of your statement descriptor
        </div>
        <ErrorMessage
          name="teamSize"
          component="div"
          className="text-danger mt-2"
        />
      </div>

      <div className="mb-10 fv-row">
        <label className="form-label mb-3 required">Company Name</label>
        <Field
          type="text"
          className="form-control form-control-lg form-control-solid"
          name="name"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="name" />
        </div>
      </div>

      <div className="fv-row mb-10 fv-plugins-icon-container">
        <label className="d-block fw-semibold fs-6 mb-5">
          <span>Company Logo</span>
        </label>
        <div className="image-input image-input-outline">
          <div
            className="image-input-wrapper w-125px h-125px"
            style={{
              backgroundImage: `url(${
                preview || "/media/svg/files/blank-image.svg"
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <label
            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            aria-label="Change avatar"
            data-bs-original-title="Change avatar"
          >
            <i className="ki-duotone ki-pencil fs-7">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="file"
              name="logo"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
            <input type="hidden" name="avatar_remove" />
          </label>
          {preview && (
            <span
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="cancel"
              data-bs-toggle="tooltip"
              aria-label="Cancel avatar"
              data-bs-original-title="Cancel avatar"
              onClick={handleFileRemove}
            >
              <i className="ki-duotone ki-cross fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </span>
          )}
          {preview && (
            <span
              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
              data-kt-image-input-action="remove"
              data-bs-toggle="tooltip"
              aria-label="Remove avatar"
              data-bs-original-title="Remove avatar"
              onClick={handleFileRemove}
            >
              <i className="ki-duotone ki-cross fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </span>
          )}
        </div>
        <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
        <div className="text-danger mt-2">
          <ErrorMessage name="logo" />
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="form-label mb-3 required">Company Type</label>
        <Field
          as="select"
          className="form-select form-select-solid form-select-white"
          name="industry"
        >
          <option value="">Select a company type</option>
          {[
            "S Corporation",
            "C Corporation",
            "Sole Proprietorship Non-profit",
            "Limited Liability",
            "General Partnership",
          ].map((type, index) => (
            <option value={type.toLowerCase()} key={index}>
              {type}
            </option>
          ))}
        </Field>
        <div className="text-danger mt-2">
          <ErrorMessage name="industry" />
        </div>
      </div>

      <div className="mb-10 fv-row">
        <label className="form-label mb-3">Company Description</label>
        <Field
          as="textarea"
          className="form-control form-control-lg form-control-solid"
          name="companyDescription"
        />
        <div className="text-danger mt-2">
          <ErrorMessage name="companyDescription" />
        </div>
      </div>
    </div>
  );
});

export default CompanyDetailsForm;
