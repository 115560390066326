export default async function stopChat(userDocumentId: number): Promise<any> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/stopChat`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userDocumentId: userDocumentId }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res)
      .then((data) => {
        if (data) {
          resolve(data);
        } else {
          reject(new Error("No data received"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
