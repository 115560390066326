import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { observer } from "mobx-react";

import { PageLink, PageTitle } from "../../../layout/core";

import CompanyModelsPage from "./CompanyModelsPage";

const companyModelsBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/models",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class CompanyModelsWrapper extends React.Component<WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <>
        <PageTitle breadcrumbs={companyModelsBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.COMPANY.MODELS" })}
        </PageTitle>

        <CompanyModelsPage />
      </>
    );
  }
}

const CompanyModelsWrapperWithIntl = injectIntl(CompanyModelsWrapper);

export { CompanyModelsWrapperWithIntl as CompanyModelsWrapper };
