// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserDepartment } from "../../app/models/CompanyUserDepartment";

export default async function createCompanyUserDepartment(
  company_user_id: number,
  department_id: number
): Promise<CompanyUserDepartment> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
      mutation CreateCompanyUserDepartment(
        $company_user_id: Int!
        $department_id: Int!
      ) {
        createCompanyUserDepartment(
          company_user_id: $company_user_id
          department_id: $department_id
        ) {
          id
          company_user_id
          department_id
          company_user {
            id
            user_id
            company_id
            role_id
            status
            user {
              id
              name
            }
            user_role {
              id
              name
            }
          }
          department {
            id
            name
            description
            color
          }
        }
      }
    `;

    client
      .mutate({
        mutation,
        variables: { company_user_id, department_id },
      })
      .then((createCompanyUserDepartmentMutationResult: any) => {
        const companyUserDepartment: CompanyUserDepartment = get(
          createCompanyUserDepartmentMutationResult,
          "data.createCompanyUserDepartment",
          null
        );

        if (companyUserDepartment) {
          resolve(companyUserDepartment);
        } else {
          reject(
            new Error("The mutation did not return a department as a result")
          );
        }
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
}
