// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUser } from "../../app/models/CompanyUser";

export default async function createCompanyUser(
  user_id: number,
  company_id: number,
  role_id: number,
  status: string
): Promise<CompanyUser> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createCompanyUser(
        user_id: ${user_id}
        company_id: ${company_id}
        role_id: ${role_id}
        status: "${status}"
      ) {
        id
        user_id
        company_id
        role_id
        status
        user {
          id
          auth0_id
          picture
          name
          email
          email_verified
          gender
          locale
          phone_number
          phone_number_verified
          settings
        }
        company {
          id
          user_id
          name
          logo
          created_at
        }
        user_role {
          id
          name
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyUserMutationResult: any) => {
        const companyUser: CompanyUser = get(
          createCompanyUserMutationResult,
          "data.createCompanyUser",
          null
        );

        if (companyUser) {
          resolve(companyUser);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
