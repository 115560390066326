/* @flow */
import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import stores from "../app/stores";

class ApolloClientHelper {
  private client: any;

  init = () => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    });

    const errorLink = onError(({ graphQLErrors, networkError, response }) => {
      console.warn("Network error: ", networkError);
      console.warn("Network response: ", response);
      console.warn("Network graphQLErrors: ", graphQLErrors);

      if (networkError) {
        if (
          networkError.message.includes("Failed to fetch") ||
          networkError.name.includes("Failed to fetch") ||
          networkError.stack?.includes("Failed to fetch")
        ) {
          stores.userStore.logout();
        } else if (networkError.name && networkError.name === "ServerError") {
          stores.userStore.logout();
        }
      }
    });

    if (process.env.REACT_APP_IS_DEV === "false") {
      this.client = new ApolloClient({
        link: from([errorLink, httpLink]),
        cache: new InMemoryCache(),
      });
    } else {
      this.client = new ApolloClient({
        uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
        cache: new InMemoryCache(),
      });
    }
  };

  getApolloClient = () => this.client;
}

const apolloClient = new ApolloClientHelper();

export default apolloClient;
