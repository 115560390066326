/* @flow */

export type CreateApiKeyResult = {
  success: boolean;
  apiKey: string;
};

export default async function createApiKey(
  companyId: number
): Promise<CreateApiKeyResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/createApiKey`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyId: companyId,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: CreateApiKeyResult = data;

          resolve(result);
        } else {
          reject();
        }
      });
  });
}
