import React, { useState } from "react";
import CreateCompanyRedirectPage from "./CreateCompany/CreateCompanyRedirectPage";
import CompanyDetails from "./CreateCompany/CompanyDetails";
import {
  WizardLayout,
  WizardHeader,
  WizardContent,
} from "./CreateCompany/WizardComponents";

const WizardsPage = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    teamSize: "1-1",
    name: "",
    logo: null,
    industry: "",
  });

  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handleBack = () => setStep((prevStep) => prevStep - 1);

  // This function is triggered after the company creation
  const handleFinish = () => {
    window.location.href = "/";
  };

  return (
    <WizardLayout>
      {step !== 0 && (
        <WizardHeader>
          <div
            className={`stepper-item ${step === 1 ? "current" : ""}`}
            data-kt-stepper-element="nav"
          >
            <h3 className="stepper-title">Company Details</h3>
          </div>
        </WizardHeader>
      )}
      <WizardContent>
        {step === 0 && <CreateCompanyRedirectPage onNext={handleNext} />}
        {step === 1 && (
          <CompanyDetails
            onNext={handleFinish} // Finish after the company is created
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </WizardContent>
    </WizardLayout>
  );
};

export default WizardsPage;
