import { observable, when } from "mobx";

import { getUserRoles } from "../../helpers/api";
import { UserRole } from "../models/UserRole";

import RootStore from "./RootStore";

export default class UserRoleStore {
  @observable userRoles: UserRole[] = [];

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.getUserRoles();
      }
    );
  }

  private getUserRoles = async () => {
    this.userRoles = await getUserRoles();
  };
}
