// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyModel } from "../../app/models/CompanyModel";

export default async function createCompanyModel(
  model_id: number,
  company_id: number,
  credentials: string,
  status: string,
  modified_at: number
): Promise<CompanyModel> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createCompanyModel(
        model_id: ${model_id}
        company_id: ${company_id}
        credentials: "${credentials}"
        status: "${status}"
        modified_at: ${modified_at}
      ) {
        id
        model_id
        company_id
        credentials
        status
        modified_at
        model {
          id
          name
          description
          logo
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyModelMutationResult: any) => {
        const companyModel: CompanyModel = get(
          createCompanyModelMutationResult,
          "data.createCompanyModel",
          null
        );

        if (companyModel) {
          resolve(companyModel);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
