/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyStorage } from "../../app/models/CompanyStorage";

export default async function getCompanyStorages(
  company_id: number
): Promise<CompanyStorage[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyStoragesQuery($company_id: ID!) {
        companyStorages(company_id: $company_id) {
          id
          storage_id
          company_id
          credentials
          status
          modified_at
          is_default
          storage {
            id
            name
            description
            logo
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyStoragesQueryResult: any) => {
        const companyStorages: CompanyStorage[] = get(
          companyStoragesQueryResult,
          "data.companyStorages",
          null
        );

        resolve(companyStorages);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
