/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteUser(id: number): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
        deleteUser(
        id: ${id}
      ) 
    }
  `;

  const deleteUserMutationResult = await client.mutate({ mutation });

  const result = get(deleteUserMutationResult, "data.deleteUser", null);

  return result !== null && result[0] > 0;
}
