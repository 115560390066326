/* @flow */
import { Configuration, StreamingAvatarApi } from "@heygen/streaming-avatar";

export default async function getAvatar(): Promise<StreamingAvatarApi> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/get-avatar-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const token = data.token;
          const avatar = new StreamingAvatarApi(
            new Configuration({
              accessToken: token,
            })
          );

          resolve(avatar);
        } else {
          reject();
        }
      });
  });
}
