// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserApp } from "../../app/models/CompanyUserApp";

export default async function createCompanyUserApp(
  user_id: number,
  app_id: number,
  company_app_id: number,
  credentials: string,
  status: string,
  invited_at: number
): Promise<CompanyUserApp> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createCompanyUserApp(
        user_id: ${user_id}
        app_id: ${app_id}
        company_app_id: ${company_app_id}
        credentials: "${credentials}"
        status: "${status}"
        invited_at: ${invited_at}
      ) {
        id
        user_id
        status
        app_id
        company_app_id
        status
        invited_at
        user {
          id
          email
          picture
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyUserAppMutationResult: any) => {
        const companyUserApp: CompanyUserApp = get(
          createCompanyUserAppMutationResult,
          "data.createCompanyUserApp",
          null
        );

        if (companyUserApp) {
          resolve(companyUserApp);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
