import React from "react";
import { ColumnInstance } from "react-table";

import { CustomUser } from "../../../../../models/CustomUser";

type Props = {
  column: ColumnInstance<CustomUser>;
};

export class CustomHeaderColumn extends React.Component<Props> {
  render() {
    const { column } = this.props;

    return (
      <>
        {column.Header && typeof column.Header === "string" ? (
          <th {...column.getHeaderProps()}>{column.render("Header")}</th>
        ) : (
          column.render("Header")
        )}
      </>
    );
  }
}
