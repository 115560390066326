/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";
import ApolloClientHelper from "../ApolloClientHelper";
import { Department } from "../../app/models/Department";

export default async function getDepartments(): Promise<Department[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query GetDepartments {
        departments {
          id
          name
          description
          color
          logo
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((DepartmentsQueryResult: any) => {
        const departments: Department[] = get(
          DepartmentsQueryResult,
          "data.departments",
          []
        );

        resolve(departments);
      })
      .catch((error: any) => {
        console.error(error);
        resolve([]);
      });
  });
}
