/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Company } from "../../app/models/Company";

export default async function updateCompany(
  company: Company
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateCompany(
        id: ${company.id}
        name: "${company.name}"
        logo: "${company.logo}"
        teamSize: "${company.teamSize}"
        industry: "${company.industry}"
        plan: "${company.plan}"
        is_index_created: ${Number(company.is_index_created)}
        index_updated_at: ${company.index_updated_at}
      ) 
    }
  `;

  const updateCompanyMutationResult = await client.mutate({ mutation });

  const result = get(updateCompanyMutationResult, "data.updateCompany", null);

  return result !== null && result[0] > 0;
}
