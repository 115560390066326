import React from "react";

import { KTIcon } from "../../../helpers";
import stores from "../../stores";
import {
  createCheckoutSession,
  getStripeProfileSession,
} from "../../../helpers/api";
import TokenPriceTable from "../../../partials/modals/upgrade-plan/TokenPriceTable";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

if (!stripeKey) {
  throw new Error("Stripe key is not defined");
}

const UpgradePlanPage: React.FC = () => {
  const totalFreeTokenCount = process.env.REACT_APP_FREE_TOKEN_COUNT
    ? parseInt(process.env.REACT_APP_FREE_TOKEN_COUNT)
    : 25000;
  const usedTokenCount =
    totalFreeTokenCount - stores.companyStore.freeTokenCount;
  const progress = Math.round((100 * usedTokenCount) / totalFreeTokenCount);

  const color =
    progress < 50 ? "primary" : progress > 75 ? "danger" : "warning";

  const handleUpgradePlan = async () => {
    try {
      if (stores.userStore.currentUser.stripeProfile) {
        const priceIds = stores.companyModelStore.models.flatMap(
          (model) => model.stripe_price_id
        );

        const checkoutSession = await createCheckoutSession(
          stores.userStore.currentUser.stripeProfile.id,
          priceIds,
          `${process.env.REACT_APP_PUBLIC_URL}/success`
        );

        window.location.href = checkoutSession.url;
        sessionStorage.setItem("stripeSessionId", checkoutSession.id);
      }
    } catch (error) {
      console.error("Error during subscription update:", error);
    }
  };

  const handleMyPlanClick = async () => {
    if (stores.userStore.currentUser.stripeProfile) {
      const getStripeProfileSessionResult = await getStripeProfileSession(
        stores.userStore.currentUser.stripeProfile.id,
        //@ts-ignore
        process.env.REACT_APP_PUBLIC_URL
      );

      window.location.href = getStripeProfileSessionResult.url;
    }
  };

  return (
    <div className="container w-75 mt-5 mb-5 pb-10 pt-5">
      <div className="card shadow-sm">
        <div className="card-body rounded">
          <div className="mb-1 text-center">
            <h1 className="mb-3">
              {stores.userStore.hasUnpaidInvoices
                ? "You have an unpaid invoice."
                : "Free Token Expired: Upgrade Your Plan"}
            </h1>
            <div className="text-muted fw-bold fs-5 mt-5 mb-5">
              {stores.userStore.hasUnpaidInvoices
                ? "Please check your unpaid invoices."
                : "Your free usage token has expired. To continue enjoying our services with enhanced features, please upgrade your plan."}
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="row mt-10">
              <div className="mb-10 mb-lg-0">
                <div className="d-flex justify-content-center mb-5">
                  <div className="card card-flush border-0 shadow-none w-100 max-w-600px">
                    <div className="card-body p-3">
                      <div className="d-flex align-items-center">
                        <KTIcon
                          iconName="duotune/abstract/abs021"
                          className={`svg-icon svg-icon-3x text-${color} me-4`}
                        />
                        <div className="d-flex flex-column flex-grow-1 m-2">
                          <div className="d-flex mb-2">
                            <span
                              style={{ flex: 1 }}
                              className={`text-${color} fw-bold fs-6 `}
                            >
                              Free Token Usage
                            </span>

                            <span className="fw-semibold fs-7 text-muted ms-4">{`${
                              progress > 100 ? 100 : progress
                            }%`}</span>
                          </div>

                          <div className="progress h-8px w-100 bg-light-dark">
                            <div
                              className={`progress-bar bg-${color}`}
                              role="progressbar"
                              style={{
                                width: `${progress > 100 ? 100 : progress}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-4">
                  <TokenPriceTable models={stores.companyModelStore.models} />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-center flex-row-fluid pt-12">
            <div className="d-flex flex-center">
              {!stores.userStore.hasUnpaidInvoices ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpgradePlan}
                >
                  Upgrade to Premium Plan
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleMyPlanClick}
                >
                  My Plan
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanPage;
