import React from "react";

import { CompanyAppStatus } from "../../../../../../helpers/Enums";

type Props = {
  status: string;
};

export class CompanyAppStatusCell extends React.Component<Props> {
  render() {
    const { status } = this.props;

    return (
      <div className="text-center">
        {status === CompanyAppStatus.Connected ? (
          <span className="badge badge-light-success fw-bolder">Connected</span>
        ) : status === CompanyAppStatus.InProgress ? (
          <span className="badge badge-light-warning fw-bolder">
            In Progress
          </span>
        ) : status === CompanyAppStatus.Failed ? (
          <span className="badge badge-light-danger fw-bolder">
            Connection Failed
          </span>
        ) : null}
      </div>
    );
  }
}
