/* @flow */

import { StripeSession } from "../../app/models/User";

export default async function getStripeProfileSession(
  customerId: string,
  returnUrl: string
): Promise<StripeSession> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/get-stripe-profile-session`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customerId: customerId,
        returnUrl: returnUrl,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const stripeSession: StripeSession = data;

          resolve(stripeSession);
        } else {
          reject();
        }
      });
  });
}
