/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyModel } from "../../app/models/CompanyModel";

export default async function updateCompanyApp(
  companyModel: CompanyModel
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateCompanyModel(
        id: ${companyModel.id}
        model_id: ${companyModel.model_id}
        company_id: ${companyModel.company_id}
        credentials: "${companyModel.credentials}"
        status: "${companyModel.status}"
        modified_at: ${companyModel.modified_at}
      ) 
    }
  `;

  const updateCompanyModelMutationResult = await client.mutate({ mutation });

  const result = get(
    updateCompanyModelMutationResult,
    "data.updateCompanyModel",
    null
  );

  return result !== null && result[0] > 0;
}
