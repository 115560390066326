import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { observer } from "mobx-react";

import { PageLink, PageTitle } from "../../../layout/core";
import stores from "../../stores";
import { App } from "../../models/App";
import { getApps } from "../../../helpers/api";

import ChatPage from "./ChatPage";
import ConnectAppModal from "./connect-app-modal/ConnectAppModal";

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/chat",
    isSeparator: false,
    isActive: false,
  },
];

interface State {
  apps: App[];
}

@observer
class ChatWrapper extends React.Component<WrappedComponentProps, State> {
  constructor(props: WrappedComponentProps) {
    super(props);

    this.state = {
      apps: [],
    };
  }

  componentWillMount(): void {
    this.getApps();
  }

  private getApps = async () => {
    const apps = await getApps();

    this.setState({ apps: apps.filter((app) => app.name !== "General") });
  };

  render() {
    const { apps } = this.state;
    const { intl } = this.props;

    return (
      <>
        <PageTitle breadcrumbs={chatBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.CHAT" })}
        </PageTitle>

        <ChatPage
          userDocument={stores.userDocumentStore.selectedUserDocument}
        />

        {stores.companyAppStore.isConnectAppModalOpened ? (
          <ConnectAppModal apps={apps} />
        ) : null}
      </>
    );
  }
}

const ChatWrapperWithIntl = injectIntl(ChatWrapper);

export { ChatWrapperWithIntl as ChatWrapper };
