/* @flow */

export type CheckUnpaidInvoicesResult = {
  success: boolean;
  hasUnpaidInvoices: boolean;
};

export default async function checkUnpaidInvoices(
  email: string
): Promise<CheckUnpaidInvoicesResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/checkUnpaidInvoices`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    }).then(async (result) => {
      const checkUnpaidInvoicesResult: CheckUnpaidInvoicesResult =
        await result.json();

      resolve(checkUnpaidInvoicesResult);
    });
  });
}
