/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { User } from "../../app/models/User";

export default async function updateUser(user: User): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateUser(
        id: ${user.id}
        name: "${user.name}"
        email: "${user.email}"
        email_verified: ${Number(user.email_verified)}
        gender: "${user.gender}"
        locale: "${user.locale}"
        phone_number: "${user.phone_number}"
        phone_number_verified: ${Number(user.phone_number_verified)}
        settings: "${encodeURIComponent(JSON.stringify(user.settings))}"
        picture: "${user.picture}"
        is_onboarded: ${Number(user.is_onboarded)}
        created_at: ${user.created_at}
      ) 
    }
  `;

  const updateUserMutationResult = await client.mutate({ mutation });

  const result = get(updateUserMutationResult, "data.updateUser", null);

  return result !== null && result[0] > 0;
}
