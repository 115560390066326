import React, { Component } from "react";
import clsx from "clsx";
import { HeaderProps } from "react-table";

import { CustomUser } from "../../../../../models/CustomUser";

type Props = {
  className?: string;
  title?: string;
  tableProps: React.PropsWithChildren<HeaderProps<CustomUser>>;
};

interface State {
  sort?: string;
  order?: string;
}

export class CompanyUserCustomHeader extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  private sortColumn = () => {
    const { sort, order } = this.state;

    const id = this.props.tableProps.column.id;
    const isSelectedForSorting = sort === id;

    // avoid sorting for these columns
    if (id === "actions" || id === "selection") {
      return;
    }

    if (isSelectedForSorting) {
      // enable sort asc
      this.setState({ sort: id, order: "asc" });
      return;
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === "asc") {
        // enable sort desc
        this.setState({ sort: id, order: "desc" });
        return;
      }

      // disable sort
      this.setState({
        sort: undefined,
        order: undefined,
      });
    }
  };

  render() {
    const { className, title, tableProps } = this.props;
    const { sort, order } = this.state;

    const id = tableProps.column.id;
    const isSelectedForSorting = sort === id;

    return (
      <th
        {...tableProps.column.getHeaderProps()}
        className={clsx(
          className,
          isSelectedForSorting && order !== undefined && `table-sort-${order}`
        )}
        style={{ cursor: "pointer" }}
        onClick={this.sortColumn}
      >
        {title}
      </th>
    );
  }
}
