import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { checkSessionStatus } from "../../../../helpers/api";
import stores from "../../../stores";
import { Company } from "../../../models/Company";
import { KTSVG } from "../../../../helpers";

type SessionStatus = {
  status: string;
  session?: {
    plan: {
      id: string;
    };
  };
};

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const handleReturnFromStripe = async () => {
      const sessionId = sessionStorage.getItem("stripeSessionId");

      if (sessionId) {
        try {
          const sessionStatus: SessionStatus = await checkSessionStatus(
            sessionId
          );
          if (
            sessionStatus.status === "paid" &&
            sessionStatus.session?.plan?.id
          ) {
            const selectedCompany = stores.companyStore.selectedUserCompany;

            if (selectedCompany) {
              const updatedCompany: Company = {
                ...selectedCompany,
                plan: "premium",
                is_index_created: selectedCompany.is_index_created,
                logo: "",
                index_updated_at: selectedCompany.index_updated_at,
              };

              const success = await stores.companyStore.updateCompany(
                updatedCompany
              );
              if (success) {
                stores.userStore.currentUser.is_onboarded = true;
                await stores.userStore.updateUserData();
                setSuccess(true);
              } else {
                console.error("Update company failed");
              }
            }
          } else {
            console.error("Payment was not completed successfully.");
          }

          sessionStorage.removeItem("stripeSessionId");
        } catch (error) {
          console.error("Error checking session status:", error);
        }
      }
      setLoading(false);
    };

    handleReturnFromStripe();
  }, []);

  if (!stores.companyStore.selectedUserCompany) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="w-lg-600px p-10 p-lg-15 mx-auto">
          <div className="text-center mb-10">
            {success ? (
              <>
                <h1 className="text-dark mb-3">Payment Successful!</h1>
                <div className="text-gray-400 fw-bold fs-5 mb-10">
                  Your payment was completed successfully.
                </div>
                <KTSVG
                  path="/media/icons/duotune/general/gen026.svg"
                  className="svg-icon svg-icon-1 svg-icon-primary"
                />
              </>
            ) : (
              <>
                <h1 className="text-dark mb-3">Payment Failed!</h1>
                <div className="text-gray-400 fw-bold fs-5 mb-10">
                  Your payment could not be completed. Please try again.
                </div>
                <KTSVG
                  path="/media/icons/duotune/general/gen040.svg"
                  className="svg-icon svg-icon-1 svg-icon-danger"
                />
              </>
            )}
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate("/chat")}
            >
              Go to CompanyDNA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
