// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserDocument } from "../../app/models/UserDocument";

export default async function createUserDocument(
  user_id: number,
  name: string,
  created_at: number
): Promise<UserDocument> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createUserDocument(
        user_id: ${user_id}
        name: "${name}"
        created_at: ${created_at}
      ) {
        id
        user_id
        name
        created_at
        user {
          id
          name
          picture
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createUserDocumentMutationResult: any) => {
        const userDocument: UserDocument = get(
          createUserDocumentMutationResult,
          "data.createUserDocument",
          null
        );

        if (userDocument) {
          resolve(userDocument);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
