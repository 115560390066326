/* @flow */

import { StripeProfile } from "../../app/models/User";

export default async function createStripeProfile(
  email: string
): Promise<StripeProfile> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/create-customer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const stripeProfile: StripeProfile = data;

          resolve(stripeProfile);
        } else {
          reject();
        }
      });
  });
}
