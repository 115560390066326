/* @flow */

import { Subscription } from "../../app/models/Subscription";
import stores from "../../app/stores";

export default async function getSubscription(
  customerId: string
): Promise<Subscription | undefined> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/customer-subscriptions`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ customerId: customerId }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    }).then(async (result) => {
      try {
        if (result.status === 401) {
          stores.userStore.logout();
          resolve(undefined);
        } else {
          const data = await result.json();

          let subscriptionItem: Subscription | undefined = data[0];

          resolve(subscriptionItem);
        }
      } catch (error) {
        resolve(undefined);
      }
    });
  });
}
