/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyStorage } from "../../app/models/CompanyStorage";

export default async function updateCompanyStorage(
  companyStorage: CompanyStorage
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateCompanyStorage(
        id: ${companyStorage.id}
        storage_id: ${companyStorage.storage_id}
        company_id: ${companyStorage.company_id}
        credentials: "${companyStorage.credentials}"
        status: "${companyStorage.status}"
        modified_at: ${companyStorage.modified_at}
        is_default: ${companyStorage.is_default}
      ) 
    }
  `;

  const updateCompanyStorageMutationResult = await client.mutate({ mutation });

  const result = get(
    updateCompanyStorageMutationResult,
    "data.updateCompanyStorage",
    null
  );

  return result !== null && result[0] > 0;
}
