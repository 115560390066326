/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Storage } from "../../app/models/Storage";

export default async function getStorages(): Promise<Storage[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query StoragesQuery {
        storages {
          id
          name
          description
          logo
          published
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((StoragesQueryResult: any) => {
        const storages: Storage[] = get(
          StoragesQueryResult,
          "data.storages",
          null
        );

        resolve(storages);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
