import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { PageTitle, PageLink } from "../../../../layout/core";
import CreateNewAvatar from "./CreateNewAvatar";

function withRouter(Component: any) {
  function ComponentWithRouter(props: any) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}

const appsBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/create-new-avatar",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class CreateNewAvatarWrapper extends React.Component {
  render() {
    //@ts-ignore
    // const appId = this.props.params.id;

    return (
      <>
        <PageTitle breadcrumbs={appsBreadCrumbs}>Create New Avatar</PageTitle>

        <CreateNewAvatar />
      </>
    );
  }
}

export default withRouter(CreateNewAvatarWrapper);
