/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteCompanyStorage(
  id: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      deleteCompanyStorage(
        id: ${id}
      ) 
    }
  `;

  const deleteCompanyStorageMutationResult = await client.mutate({ mutation });

  const result = get(
    deleteCompanyStorageMutationResult,
    "data.deleteCompanyStorage",
    null
  );

  return result !== null && result[0] > 0;
}
