import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { observer } from "mobx-react";

import { PageLink, PageTitle } from "../../../layout/core";

import CompanyStoragesPage from "./CompanyStoragesPage";

const companyStoragesBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/storages",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class CompanyStoragesWrapper extends React.Component<WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <>
        <PageTitle breadcrumbs={companyStoragesBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.COMPANY.STORAGES" })}
        </PageTitle>

        <CompanyStoragesPage />
      </>
    );
  }
}

const CompanyStoragesWrapperWithIntl = injectIntl(CompanyStoragesWrapper);

export { CompanyStoragesWrapperWithIntl as CompanyStoragesWrapper };
