import clsx from "clsx";
import React from "react";
import { Row } from "react-table";

import { CustomUser } from "../../../../../models/CustomUser";

type Props = {
  row: Row<CustomUser>;
};

export class CustomRow extends React.Component<Props> {
  private renderCell(cell: any) {
    return (
      <td
        {...cell.getCellProps()}
        className={clsx({
          "text-end min-w-100px": cell.column.id === "actions",
        })}
      >
        {cell.render("Cell")}
      </td>
    );
  }

  render() {
    const { row } = this.props;

    return <tr {...row.getRowProps()}>{row.cells.map(this.renderCell)}</tr>;
  }
}
