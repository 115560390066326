/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserDocument } from "../../app/models/UserDocument";

export default async function getUserDocuments(
  user_id: number
): Promise<UserDocument[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserDocumentsQuery($user_id: ID!) {
        userDocuments(user_id: $user_id) {
          id
          user_id
          name
          selected_apps
          created_at
          user {
            id
            name
            picture
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          user_id: user_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((userDocumentsQueryResult: any) => {
        const userDocuments: UserDocument[] = get(
          userDocumentsQueryResult,
          "data.userDocuments",
          null
        );

        resolve(userDocuments);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
