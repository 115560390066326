/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteCompanyUserDepartment(
  id: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      deleteCompanyUserDepartment(
        id: ${id}
      ) 
    }
  `;

  const deleteCompanyUserDepartmentMutationResult = await client.mutate({
    mutation,
  });

  const result = get(
    deleteCompanyUserDepartmentMutationResult,
    "data.deleteCompanyUserDepartment",
    null
  );

  return result !== null && result[0] > 0;
}
