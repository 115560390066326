/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { observer } from "mobx-react";
import { Column } from "react-table";

import { KTIcon } from "../../../../helpers";
import stores from "../../../stores";
import { App } from "../../../models/App";
import { CompanyApp } from "../../../models/CompanyApp";
import { CompanyAppStatusCell } from "../../CompanyApps/CompanyAppList/table/columns/CompanyAppStatusCell";
import { CompanyAppStatus } from "../../../../helpers/Enums";

import DefaultAppCard from "./AppCards/DefaultAppCard";
import ShopifyCard from "./AppCards/ShopifyCard";
import MailchimpCard from "./AppCards/MailchimpCard";
import FigmaCard from "./AppCards/FigmaCard";

interface Props {
  apps: App[];
}

interface State {
  selectedApp?: App;
  activeCardId?: number;
  searchQuery: string;
  sortOrder: "asc" | "desc" | "none";
  filterByStatus: string;
  filterByPublished: boolean;
}

@observer
export default class ConnectAppModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchQuery: "",
      sortOrder: "none",
      filterByStatus: "All",
      filterByPublished: true,
    };
  }

  companyAppColumns: ReadonlyArray<Column<CompanyApp>> = [
    {
      id: "status",
      Cell: ({ ...props }) => (
        <CompanyAppStatusCell status={props.data[props.row.index].status} />
      ),
    },
  ];
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.apps.length !== this.props.apps.length ||
      prevState.filterByStatus !== this.state.filterByStatus ||
      prevState.filterByPublished !== this.state.filterByPublished ||
      prevState.searchQuery !== this.state.searchQuery ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
    }
  }

  private toggleActiveCard = (appId?: number) => {
    this.setState({ activeCardId: appId });
  };

  private getCompanyApp = (app: App): CompanyApp | undefined => {
    const appIndex = stores.companyAppStore.companyApps.findIndex(
      (companyApp) => companyApp.app.id === app.id
    );

    if (appIndex !== -1) return stores.companyAppStore.companyApps[appIndex];
  };

  private filterApps = (apps: App[]): App[] => {
    const { filterByStatus, filterByPublished, searchQuery } = this.state;

    return apps.filter((app) => {
      const companyApp = this.getCompanyApp(app);
      let appStatus: CompanyAppStatus = CompanyAppStatus.None;
      if (companyApp)
        appStatus =
          CompanyAppStatus[companyApp.status as keyof typeof CompanyAppStatus];

      const isPublished = app.published;
      const appName = app.name.toLowerCase();

      return (
        (filterByStatus === "All" || appStatus === filterByStatus) &&
        (!filterByPublished || isPublished) &&
        (searchQuery === "" || appName.includes(searchQuery.toLowerCase()))
      );
    });
  };

  private handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value });
  };

  private sortApps = () => {
    const { apps } = this.props;
    const { sortOrder } = this.state;

    if (sortOrder === "asc") {
      return [...apps].sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOrder === "desc") {
      return [...apps].sort((a, b) => b.name.localeCompare(a.name));
    } else {
      return apps;
    }
  };

  private handleRequestApp = () => {
    stores.companyAppStore.isAddAppModalOpened = true;
  };

  private handleStatusFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    this.setState({ filterByStatus: e.target.value });
  };

  private toggleFilterByPublished = () => {
    this.setState((prevState) => ({
      filterByPublished: !prevState.filterByPublished,
    }));
  };

  private renderAppCard = (app: App) => {
    const isActiveCard = this.state.activeCardId === app.id;
    const companyApp = this.getCompanyApp(app);
    let appStatus: CompanyAppStatus = CompanyAppStatus.None;

    if (companyApp)
      appStatus =
        CompanyAppStatus[companyApp.status as keyof typeof CompanyAppStatus];
    const isClickable = appStatus !== CompanyAppStatus.None;

    switch (app.id.toString()) {
      // Shopify
      case "50":
        return (
          <ShopifyCard
            key={app.id}
            app={app}
            appStatus={appStatus}
            companyApp={companyApp}
            isActiveCard={isActiveCard}
            isClickable={isClickable}
            toggleActiveCard={this.toggleActiveCard}
          />
        );
      // Figma
      case "54":
        return (
          <FigmaCard
            key={app.id}
            app={app}
            appStatus={appStatus}
            companyApp={companyApp}
            isActiveCard={isActiveCard}
            isClickable={isClickable}
            toggleActiveCard={this.toggleActiveCard}
          />
        );
      // Mailchimp
      case "57":
        return (
          <MailchimpCard
            key={app.id}
            app={app}
            appStatus={appStatus}
            companyApp={companyApp}
            isActiveCard={isActiveCard}
            isClickable={isClickable}
            toggleActiveCard={this.toggleActiveCard}
          />
        );
    }

    return (
      <DefaultAppCard
        key={app.id}
        app={app}
        appStatus={appStatus}
        companyApp={companyApp}
        isClickable={isClickable}
      />
    );
  };

  private handleClose = () => {
    stores.companyAppStore.isConnectAppModalOpened = false;
  };

  render() {
    const { searchQuery, filterByStatus, filterByPublished } = this.state;

    const displayedApps = this.filterApps(this.sortApps());

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              width: "90%",
              height: "90%",
            }}
          >
            <div className="modal-content" style={{ minHeight: "90%" }}>
              <div className="modal-header bg-light pb-0 border-0 justify-content-end">
                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                  onClick={this.handleClose}
                  style={{ cursor: "pointer" }}
                >
                  <KTIcon iconName="cross" className="fs-1" />
                </div>
              </div>

              <div
                style={{ borderRadius: 10 }}
                className="modal-body bg-light py-lg-10 px-lg-10"
              >
                <div className="w-100">
                  <div className="d-flex flex-wrap flex-stack mb-5">
                    <span className="fs-6 text-primary-400 fw-bold mt-7">
                      {stores.companyAppStore.companyApps.length - 1} App
                      Connected
                      <div className="mt-10">
                        <label className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={filterByPublished}
                            onChange={this.toggleFilterByPublished}
                          />
                          <span className="form-check-label">
                            Show only available apps
                          </span>
                        </label>
                      </div>
                    </span>

                    <div className="d-flex align-items-center justify-content-end justify-content-between mt-4 ">
                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={this.handleRequestApp}
                      >
                        <KTIcon iconName="plus" className="fs-2" />
                        Request New App
                      </button>
                      <div className="d-flex ">
                        <select
                          id="statusFilter"
                          className="form-select border-0 w-175px  ps-6"
                          value={filterByStatus}
                          onChange={this.handleStatusFilterChange}
                        >
                          <option value="All">All</option>
                          <option value="Connected">Connected</option>
                          <option value="InProgress">In Progress</option>
                          <option value="Failed">Connection Failed</option>
                          <option value="">Not Connected</option>
                        </select>
                      </div>

                      <div className="d-flex align-items-center justify-content-end ms-3 ">
                        <KTIcon
                          iconName="magnifier"
                          className="fs-1 position-absolute px-3"
                        />
                        <input
                          type="text"
                          data-kt-user-table-filter="search"
                          className="form-control border-0 w-200px ps-6 "
                          placeholder="Search App..."
                          value={searchQuery}
                          onChange={this.handleSearchChange}
                        />
                      </div>

                      <div className="d-flex align-items-center ms-3">
                        <button
                          className="btn  p-0"
                          onClick={() => {
                            this.setState((prevState) => ({
                              sortOrder:
                                prevState.sortOrder === "asc" ? "desc" : "asc",
                            }));
                          }}
                        >
                          {this.state.sortOrder === "asc" ? (
                            <i className="fas fa-sort-alpha-up fs-1"></i>
                          ) : (
                            <i className="fas fa-sort-alpha-down fs-1"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 15,
                      maxHeight: "60vh",
                      overflow: "scroll",
                    }}
                    className="row g-6 g-xl-9 pb-20"
                  >
                    {displayedApps.map((app) => this.renderAppCard(app))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
