/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserInvite } from "../../app/models/CompanyUserInvite";

export default async function getCompanyUserInvites(
  company_id: number
): Promise<CompanyUserInvite[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyUserInvitesQuery($company_id: ID!) {
        companyUserInvites(company_id: $company_id) {
          id
          name
          email
          company_id
          role_id
          status
          invited_at
          user_role {
            id
            name
          }
          company {
            id
            user_id
            name
            logo
            created_at
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyUserInvitesQueryResult: any) => {
        const companyUserInvites: CompanyUserInvite[] = get(
          companyUserInvitesQueryResult,
          "data.companyUserInvites",
          null
        );

        resolve(companyUserInvites);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
