// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserInvite } from "../../app/models/CompanyUserInvite";

export default async function createCompanyUserInvite(
  name: string,
  email: string,
  company_id: number,
  role_id: number,
  status: string,
  invited_at: number
): Promise<CompanyUserInvite> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createCompanyUserInvite(
        name: "${name}"
        email: "${email}"
        company_id: ${company_id}
        role_id: ${role_id}
        status: "${status}"
        invited_at: ${invited_at}
      ) {
        id
        name
        email
        company_id
        role_id
        status
        invited_at
        company {
          id
          user_id
          name
          logo
          created_at
        }
        user_role {
          id
          name
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyUserInviteMutationResult: any) => {
        const companyUserInvite: CompanyUserInvite = get(
          createCompanyUserInviteMutationResult,
          "data.createCompanyUserInvite",
          null
        );

        if (companyUserInvite) {
          resolve(companyUserInvite);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
