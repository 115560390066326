/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyModel } from "../../app/models/CompanyModel";

export default async function getCompanyModels(
  company_id: number
): Promise<CompanyModel[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyModelsQuery($company_id: ID!) {
        companyModels(company_id: $company_id) {
          id
          model_id
          company_id
          credentials
          status
          modified_at
          model {
            id
            stripe_price_id
            name
            description
            logo
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyModelsQueryResult: any) => {
        const companyModels: CompanyModel[] = get(
          companyModelsQueryResult,
          "data.companyModels",
          null
        );

        resolve(companyModels);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
