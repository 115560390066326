import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { MenuComponent } from "../assets/ts/components";
import { ActivityDrawer, DrawerMessenger, UpgradePlan } from "../partials";
import AppParamsInputModal from "../app/modals/app-params-input-modal/AppParamsInputModal";

// import { RightToolbar } from "../partials/layout/RightToolbar";
import { Content } from "./components/Content";
// import { Footer } from "./components/Footer";
import { ScrollTop } from "./components/ScrollTop";
import { AsideDefault } from "./components/aside/AsideDefault";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { PageDataProvider } from "./core";
import stores from "../app/stores";

const MasterLayout = () => {
  const location = useLocation();
  const [isElectron, setIsElectron] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  useEffect(() => {
    // Check if running in Electron
    setIsElectron(window.electronAPIs?.isElectron ?? false);
  }, []);

  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root">
        {/* begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/* Render the full layout regardless of Electron */}
          <AsideDefault />
          {/* begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
            style={{
              paddingLeft: isElectron ? "400px" : undefined, // Set padding-left to 400px if running in Electron
            }}
          >
            <HeaderWrapper />

            {/* begin::Content */}
            <div
              id="kt_content"
              className="content d-flex flex-column flex-column-fluid"
            >
              <Content>
                <Outlet />
              </Content>
            </div>
            {/* end::Content */}
            {/* <Footer /> */}
          </div>
          {/* end::Wrapper */}
          {/* begin:: Drawers */}
          <ActivityDrawer />
          {/* <RightToolbar /> */}
          <DrawerMessenger />
          {/* end:: Drawers */}

          {/* begin:: Modals */}
          <AppParamsInputModal
            ref={(ref) => {
              stores.companyAppStore.appParamsModalRef = ref;
            }}
          />
          <UpgradePlan />
          {/* end:: Modals */}
        </div>
        {/* end::Page */}
      </div>
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
