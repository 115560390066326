/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyTokenUsageSummary } from "../../app/models/CompanyTokenUsageSummary";

export default async function getDateRangeCompanyTokenUsagesGroups(
  company_id: number,
  start_date: string,
  end_date: string
): Promise<CompanyTokenUsageSummary[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query DateRangeCompanyTokenUsagesGroupsQuery(
        $company_id: ID!
        $start_date: String!
        $end_date: String!
      ) {
        dateRangeCompanyTokenUsagesGroups(
          company_id: $company_id
          start_date: $start_date
          end_date: $end_date
        ) {
          model_id
          total_token_count
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
          start_date: start_date,
          end_date: end_date,
        },
        fetchPolicy: "no-cache",
      })
      .then((dateRangeCompanyTokenUsagesGroupsResult: any) => {
        const companyTokenUsageSummaries: CompanyTokenUsageSummary[] = get(
          dateRangeCompanyTokenUsagesGroupsResult,
          "data.dateRangeCompanyTokenUsagesGroups",
          null
        );

        resolve(companyTokenUsageSummaries);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
