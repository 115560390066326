/* @flow */

export default async function sendInvitationMail(
  invitedUserMail: string,
  invitedUser: string,
  user: string,
  company: string
): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/sendInvitationMail`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        invitedUserMail: invitedUserMail,
        invitedUser: invitedUser,
        user: user,
        company: company,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const isSent: boolean = data;

          resolve(isSent);
        } else {
          reject();
        }
      });
  });
}
