/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { App } from "../../app/models/App";
import Functions from "../Functions";

export default async function getApps(): Promise<App[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query AppsQuery {
        apps {
          id
          name
          important
          limitations
          params
          description
          integration_id
          auth_type
          site_url
          token_info_url
          logo
          published
          is_source_available
          status
          departments {
            department {
              id
              name
              description
              color
            }
          }
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((AppsQueryResult: any) => {
        const rawApps = get(AppsQueryResult, "data.apps", []);

        const apps: App[] = rawApps.map((app: any) => ({
          ...app,
          params: Functions.transformStringToConnectionParam(app.params),
        }));

        resolve(apps);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
