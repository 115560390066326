import React from "react";
import { observer } from "mobx-react";

import i18n from "../../../../../../i18n";
import stores from "../../../../../stores";
import { KTIcon } from "../../../../../../helpers";

type Props = {};

@observer
export class CompanyUserListGrouping extends React.Component<Props> {
  private handleDeleteSelectedItems = () => {
    stores.companyUserStore.deleteSelectedCompanyUsers();
  };

  private handleAddSelectedUsersToApp = () => {
    stores.companyUserStore.isAddCompanyUsersToAppsModalOpened = true;
  };

  render() {
    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="fw-bolder me-5">
          <span className="me-2">
            {stores.companyUserStore.selectedCompanyUsers.length}
          </span>{" "}
          {i18n.CompanyUserListGrouping.selectedLabel}
        </div>

        <button
          type="button"
          className="btn btn-primary me-3"
          onClick={this.handleAddSelectedUsersToApp}
        >
          <KTIcon iconName="plus" className="fs-2" />
          {i18n.CompanyUserListGrouping.buttons.addSelectedUsersToApps}
        </button>

        <button
          type="button"
          className="btn btn-danger"
          onClick={this.handleDeleteSelectedItems}
        >
          {i18n.CompanyUserListGrouping.buttons.deleteSelected}
        </button>
      </div>
    );
  }
}
