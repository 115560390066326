/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyApp } from "../../app/models/CompanyApp";

export default async function getCompanyApps(
  company_id: number
): Promise<CompanyApp[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyAppsQuery($company_id: ID!) {
        companyApps(company_id: $company_id) {
          id
          app_id
          company_id
          credentials
          status
          modified_at
          last_sync_at
          source_ids
          app {
            id
            name
            site_url
            logo
          }
          company_user_apps {
            id
            user_id
            status
            app_id
            user {
              id
              email
              picture
            }
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyAppsQueryResult: any) => {
        const companyApps: CompanyApp[] = get(
          companyAppsQueryResult,
          "data.companyApps",
          null
        );

        for (let index = 0; index < companyApps.length; index++) {
          const companyApp = companyApps[index];
          if (companyApp) {
            if (companyApp.source_ids)
              try {
                companyApp.source_ids = JSON.parse(
                  //@ts-ignore
                  decodeURIComponent(companyApp.source_ids)
                );
              } catch (error) {
                console.error("Error parsing source_ids:", error);
              }
          }
        }

        resolve(companyApps);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
