// import React from "react";
// import { injectIntl, WrappedComponentProps } from "react-intl";

// // import { PageLink, PageTitle } from "../../../layout/core";
// import CompanyUserManagePage from "./CompanyUserManagePage";
// import { PageLink, PageTitle } from "../../../../../layout/core";
// import { useParams } from "react-router-dom";

// function withRouter(Component: any) {
//     function ComponentWithRouter(props: any) {
//       let params = useParams();
//       return <Component {...props} params={params} />;
//     }
//     return ComponentWithRouter;
//   }

// const companyUsersBreadCrumbs: Array<PageLink> = [
//   {
//     title: "Home",
//     path: "/company-user-manage-page",
//     isSeparator: false,
//     isActive: false,
//   },
// ];

// class CompanyUserManagePageWrapper extends React.Component<WrappedComponentProps> {
//   render() {

//     const appId = this.props.params.id;

//     return (
//       <>
//         <PageTitle breadcrumbs={companyUsersBreadCrumbs}>
//           CompanyUserManagePageWrapper
//         </PageTitle>

//         <CompanyUserManagePage appId={appId} />
//       </>
//     );
//   }
// }

// export default withRouter(CompanyUserManagePageWrapper);

import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../layout/core";
import CompanyUserManagePage from "./CompanyUserManagePage";

function withRouter(Component: any) {
  function ComponentWithRouter(props: any) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}

const companyUsersBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/company-user-manage-page",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class CompanyUserManagePageWrapper extends React.Component {
  render() {
    //@ts-ignore
    const companyUserId = this.props.params.companyUserId;

    return (
      <>
        <PageTitle breadcrumbs={companyUsersBreadCrumbs}>
          Manage Connection Data
        </PageTitle>

        <CompanyUserManagePage companyUserId={companyUserId} />
      </>
    );
  }
}

export default withRouter(CompanyUserManagePageWrapper);
