import React from "react";
import { observer } from "mobx-react";

import { KTCard } from "../../../../helpers";
import stores from "../../../stores";

import { ListViewProvider } from "./core/ListViewProvider";
import { CompanyUsersListHeader } from "./components/header/CompanyUsersListHeader";
import { CompanyUsersTable } from "./table/CompanyUsersTable";
import { CompanyUserEditModal } from "./edit-modal/CompanyUserEditModal";
import DeleteUserModal from "./delete-user-modal/DeleteUserModal";
import DeleteUserInviteModal from "./delete-user-invite-modal/DeleteUserInviteModal";
// import AddCompanyUsersToAppsModal from "./add-app-model/AddCompanyUsersToAppsModal";
// import ManageAppModal from "./manage-app-model/ManageAppModal";
// import AddCompanyUserToAppsModal from "./add-app-action-model/AddCompanyUserToAppsModal";

const CompanyUserList: React.FC = observer(() => {
  return (
    <>
      <KTCard>
        <CompanyUsersListHeader />
        <CompanyUsersTable />
      </KTCard>

      {stores.companyUserStore.isCompanyUserDeleteModalOpened ? (
        <DeleteUserModal
          selectedUser={stores.companyUserStore.selectedCompanyUser}
        />
      ) : null}

      {stores.companyUserStore.isCompanyUserInviteDeleteModalOpened ? (
        <DeleteUserInviteModal
          selectedInviteUser={stores.companyUserStore.selectedCompanyUserInvite}
        />
      ) : null}

      {stores.companyUserStore.isCompanyUserEditModalOpened ? (
        <CompanyUserEditModal />
      ) : null}

      {/* {stores.companyUserStore.isAddCompanyUsersToAppsModalOpened ? (
        <AddCompanyUsersToAppsModal
          selectedUsers={stores.companyUserStore.selectedCompanyUsers}
        />
      ) : null} */}

      {/* {stores.companyUserStore.isCompanyUserAddAppModalOpened ? (
        <AddCompanyUserToAppsModal
          selectedUser={stores.companyUserStore.selectedCompanyUser}
        />
      ) : null} */}

      {/* {stores.companyUserStore.isCompanyUserManageAppsModalOpened ? (
        <ManageAppModal
          companyUser={stores.companyUserStore.selectedCompanyUser}
        />
      ) : null} */}
    </>
  );
});

export const CompanyUsersListWrapper: React.FC = () => (
  <ListViewProvider>
    <CompanyUserList />
  </ListViewProvider>
);
