import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";

import { I18nProvider } from "../i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../layout/core";
import { MasterInit } from "../layout/MasterInit";
import { ThemeModeProvider } from "../partials";

const App = () => {
  useEffect(() => {
    ReactGA.initialize(
      `${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`
    );
  }, []);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <Outlet />
            <MasterInit />

            <ToastContainer />
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
