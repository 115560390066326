import HeaderNotificationsMenu from "../../../app/pages/header-menus/HeaderNotificationsMenu";
import { HeaderUserMenu } from "../../../app/pages/header-menus/HeaderUserMenu";
import stores from "../../../app/stores";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

const AsideFooter = () => {
  return (
    <div
      className="aside-footer d-flex flex-column align-items-center flex-column-auto"
      id="kt_aside_footer"
    >
      {/* begin::Subscribe */}
      {/* <div className="d-flex align-items-center mb-2"> */}
      {/* begin::Menu wrapper */}
      {/* <div
          className="btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-dismiss="click"
          title="Subscribe"
          onClick={() => navigate("/wizard")}
        >
          <KTSVG
            path={"media/icons/duotune/general/gen064.svg"}
            className="svg-icon-muted svg-icon-2x"
          />
        </div> */}
      {/* end::Menu wrapper */}
      {/* </div> */}
      {/* end::Subscribe */}

      {/* begin::Notifications */}
      <div className="d-flex align-items-center mb-5">
        {/* begin::Menu wrapper */}
        <div
          className="btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light"
          data-kt-menu-trigger="click"
          data-kt-menu-overflow="true"
          data-kt-menu-placement="top-start"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-dismiss="click"
          title="Notifications"
        >
          <KTSVG
            path={"media/icons/duotune/general/gen005.svg"}
            className="svg-icon-muted svg-icon-2x"
          />
        </div>
        {/* end::Menu wrapper */}
        <HeaderNotificationsMenu backgroundUrl="/media/misc/pattern-1.jpg" />
      </div>
      {/* end::Notifications */}

      {/* begin::User */}
      <div
        className="d-flex align-items-center mb-12"
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Menu wrapper */}
        <div
          className="cursor-pointer symbol symbol-40px"
          data-kt-menu-trigger="click"
          data-kt-menu-overflow="false"
          data-kt-menu-placement="top-start"
          title="User profile"
        >
          {stores.userStore.currentUser.picture ? (
            <img
              src={toAbsoluteUrl(stores.userStore.currentUser.picture)}
              alt="avatar"
            />
          ) : (
            <KTSVG
              path={"/media/icons/duotune/communication/com006.svg"}
              className="svg-icon-muted svg-icon-3x"
            />
          )}
        </div>
        {/* end::Menu wrapper */}
        <HeaderUserMenu />
      </div>
      {/* end::User */}
    </div>
  );
};

export { AsideFooter };
