/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { Modal } from "bootstrap";

import { KTIcon } from "../../../helpers";

export type AppParamInputItem = {
  id: string;
  appName: string;
  placeholder: string;
  value: string;
  workspaces: [];
};

interface Props {}
interface State {
  items: AppParamInputItem[];
}

@observer
export default class AppParamsInputModal extends React.Component<Props, State> {
  private modalElement?: HTMLElement;
  private modalInstance?: Modal;

  externalListener: ((items: AppParamInputItem[]) => void) | null = null;

  setExternalListener = (
    initialItems: AppParamInputItem[]
  ): Promise<AppParamInputItem[]> => {
    this.setState({ items: initialItems });

    if (this.modalInstance) {
      this.modalInstance.show();
    }

    return new Promise((resolve) => {
      this.externalListener = (items: AppParamInputItem[]) => {
        resolve(items);
      };
    });
  };

  removeExternalListener = () => {
    this.externalListener = null;

    this.setState({ items: [] });

    if (this.modalInstance) {
      this.modalInstance.hide();
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount(): void {
    const modalElement = document.getElementById("kt_modal_app_params");

    if (modalElement) {
      this.modalElement = modalElement;
      this.modalInstance = new Modal(this.modalElement);
    }
  }

  private handleSubmitClick = () => {
    const { items } = this.state;

    if (this.externalListener) this.externalListener(items);

    if (this.modalInstance) {
      this.modalInstance.hide();
    }
  };

  private handleItemChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: AppParamInputItem
  ) => {
    const { items } = this.state;

    const selectedItemIndex = items.findIndex((i) => i.id === item.id);

    if (selectedItemIndex !== -1) {
      const updatedItems = items;

      updatedItems[selectedItemIndex].value = e.target.value;

      this.setState({ items: updatedItems });
    }
  };

  private handleSelectItemChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    item: AppParamInputItem
  ) => {
    const { items } = this.state;

    const selectedItemIndex = items.findIndex((i) => i.id === item.id);

    if (selectedItemIndex !== -1) {
      const updatedItems = items;

      updatedItems[selectedItemIndex].value = e.target.value;

      this.setState({ items: updatedItems });
    }
  };

  private handleCloseModal = () => {
    if (this.externalListener) this.externalListener([]);

    if (this.modalInstance) {
      this.modalInstance.hide();
    }
  };

  private renderInput = (item: AppParamInputItem) => {
    if (item.workspaces && item.workspaces.length > 0) {
      return (
        <div key={item.id} className="fv-row mb-7 mt-2">
          <label className="required fw-bold fs-6 mb-2 mt-7">
            {item.placeholder}
          </label>

          <div className="d-flex ">
            <select
              id="statusFilter"
              className="form-select border-0 w-175px  ps-6"
              onChange={(e) => this.handleSelectItemChange(e, item)}
            >
              <option value={""}>Please Select</option>
              {item.workspaces.map((workspace) => {
                return <option value={workspace}>{workspace}</option>;
              })}
            </select>
          </div>
        </div>
      );
    } else {
      return (
        <div key={item.id} className="fv-row mb-7 mt-2">
          <label className="required fw-bold fs-6 mb-2 mt-7">
            {item.placeholder}
          </label>

          <input
            type="text"
            name="value"
            className={"form-control form-control-solid mb-3 mb-lg-0 ms-1"}
            autoComplete="off"
            onChange={(e) => this.handleItemChange(e, item)}
          />
        </div>
      );
    }
  };

  render() {
    const { items } = this.state;

    return (
      <div
        className="modal fade"
        id="kt_modal_app_params"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h2 className="fw-bolder">Monday</h2> */}

              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                onClick={this.handleCloseModal}
                style={{ cursor: "pointer" }}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>

            <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
              {items.map((item) => this.renderInput(item))}

              <button
                className="btn btn-primary"
                onClick={this.handleSubmitClick}
                disabled={
                  items.findIndex((item) => item.value === "") === -1
                    ? false
                    : true
                }
              >
                <span className="indicator-label">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
