/* @flow */

export default async function checkSessionStatus(
  sessionId: string
): Promise<{ status: string; session?: any }> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/check-session-status`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sessionId }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
