/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteUserActionLog(
  id: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      deleteUserActionLog(
        id: ${id}
      ) 
    }
  `;

  const deleteUserActionLogMutationResult = await client.mutate({ mutation });

  const result = get(
    deleteUserActionLogMutationResult,
    "data.deleteUserActionLog",
    null
  );

  return result !== null && result[0] > 0;
}
