import { gql, ApolloError } from "@apollo/client";
import ApolloClientHelper from "../ApolloClientHelper";
import type { CompanyAvatarDepartment } from "../../app/models/CompanyAvatarDepartment";
import { get } from "lodash";

async function createCompanyAvatarDepartment(
  companyAvatarId: number,
  departmentId: number
): Promise<CompanyAvatarDepartment> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation CreateCompanyAvatarDepartment(
      $company_avatar_id: Int!
      $department_id: Int!
    ) {
      createCompanyAvatarDepartment(
        company_avatar_id: $company_avatar_id
        department_id: $department_id
      ) {
        id
        company_avatar_id
        department_id
      }
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        company_avatar_id: companyAvatarId,
        department_id: departmentId,
      },
    });

    const companyAvatarDepartment = get(
      data,
      "createCompanyAvatarDepartment",
      null
    );

    if (companyAvatarDepartment) {
      return companyAvatarDepartment;
    } else {
      throw new Error("Failed to create CompanyAvatarDepartment.");
    }
  } catch (error) {
    if (error instanceof ApolloError) {
      console.error(
        "ApolloError creating CompanyAvatarDepartment:",
        error.message
      );
      console.error("GraphQL errors:", error.graphQLErrors);
      console.error("Network error:", error.networkError);
    } else {
      console.error("Error creating CompanyAvatarDepartment:", error);
    }
    throw error;
  }
}

export default createCompanyAvatarDepartment;
