/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Company } from "../../app/models/Company";

export default async function getCompany(
  id: number
): Promise<Company | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyQuery($id: ID!) {
        company(id: $id) {
          id
          user_id
          name
          logo
          created_at
          teamSize
          industry
          plan
          is_index_created
          index_updated_at
          available_avatar_count
          is_chief_available
          is_live_avatar_available
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyQueryResult: any) => {
        const company: Company = get(companyQueryResult, "data.company", null);

        if (company) {
          resolve(company);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
