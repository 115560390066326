/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";

import { toAbsoluteUrl } from "../../../helpers";
import { Model } from "../../../app/models/Model";

interface Props {
  models: Model[];
}

@observer
export default class TokenPriceTable extends React.Component<Props> {
  private renderModelItem = (model: Model, index: number) => {
    return (
      <tr key={index}>
        <td>
          <div className="d-flex align-items-center">
            <div className="symbol symbol-40px me-5">
              <span className="symbol-label bg-light">
                <img
                  src={toAbsoluteUrl(`${model.logo}`)}
                  className="h-75 align-self-end"
                  alt=""
                />
              </span>
            </div>

            <div className="d-flex justify-content-start flex-column">
              <div className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                {model.name}
              </div>

              <span className="text-muted fw-semibold text-muted d-block fs-7">
                {model.model_name}
              </span>
            </div>
          </div>
        </td>

        <td>
          <div className="text-muted fw-bold d-block mb-1 fs-6">
            ${model.price_input.toFixed(2)}
          </div>
        </td>

        <td>
          <div className="text-muted fw-bold d-block mb-1 fs-6">
            ${model.price_output.toFixed(2)}
          </div>
        </td>

        <td>
          <div className="text-dark fw-bold d-block mb-1 fs-6">
            ${model.price.toFixed(2)}
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { models } = this.props;

    return (
      <div className={`card mb-5 mb-xl-8 border border-1 rounded`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              Model Pricing Table
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              The amount to be paid will be calculated based on our price
              according to usage.
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-3 ">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted bg-light">
                  <th className="ps-4 min-w-300px rounded-start">Model</th>
                  <th className="min-w-125px">Input Price / 1M Tokens</th>
                  <th className="min-w-125px">Output Price / 1M Tokens</th>
                  <th className="min-w-125px">Our Price / 1M Tokens</th>
                </tr>
              </thead>
              {/* end::Table head */}

              {/* begin::Table body */}
              <tbody>{models.map(this.renderModelItem)}</tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    );
  }
}
