/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserDepartment } from "../../app/models/CompanyUserDepartment";

export default async function getCompanyUserDepartments(
  company_user_id: number
): Promise<CompanyUserDepartment[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyUserDepartmentsQuery($company_user_id: ID!) {
        companyUserDepartments(company_user_id: $company_user_id) {
          id
          company_user_id
          department_id
          company_user {
            id
            user_id
            company_id
            role_id
            status
            user {
              id
              name
            }
            user_role {
              id
              name
            }
          }
          department {
            id
            name
            description
            color
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_user_id: company_user_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyUserDepartmentsQueryResult: any) => {
        const companyUserDepartments: CompanyUserDepartment[] = get(
          companyUserDepartmentsQueryResult,
          "data.companyUserDepartments",
          null
        );

        resolve(companyUserDepartments);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
