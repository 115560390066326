/* @flow */
import { io } from "socket.io-client";

type MessageHandler = (message: any) => void;

class SocketHelper {
  private socketIo?: any;
  private messageHandlers: MessageHandler[] = [];

  init = () => {
    if (process.env.REACT_APP_WEB_SOCKET_URL) {
      this.socketIo = io(process.env.REACT_APP_WEB_SOCKET_URL, {
        path: "/socket.io",
        transports: ["websocket", "polling"],
        secure: true,
        reconnectionAttempts: 5,
        timeout: 20000,
      });

      this.socketIo.on("json", (json: any) => {
        this.messageHandlers.forEach((handler) => handler(json));
      });
    }
  };

  close = () => {
    if (this.socketIo) {
      this.socketIo.disconnect();
    }
  };

  addMessageHandler(handler: MessageHandler) {
    this.messageHandlers.push(handler);
  }

  removeMessageHandler(handler: MessageHandler) {
    this.messageHandlers = this.messageHandlers.filter((h) => h !== handler);
  }
}

const socketHelper = new SocketHelper();

export default socketHelper;
