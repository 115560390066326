import React from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

import { App } from "../../models/App";
import { CompanyApp } from "../../models/CompanyApp";
import { CompanyAppStatus } from "../../../helpers/Enums";
import stores from "../../stores";
import i18n from "../../../i18n";

interface DepartmentSelectionTableProps {
  selectedDepartmentId: number | null;
  onDepartmentToggle: (departmentId: number) => void;
  departmentMap: {
    [key: number]: { name: string; description?: string; apps: App[] };
  };
}

const DepartmentSelectionTable: React.FC<DepartmentSelectionTableProps> =
  observer(({ selectedDepartmentId, onDepartmentToggle, departmentMap }) => {
    const { companyApps } = stores.companyAppStore;

    const isAppConnected = (appId: number) => {
      return companyApps.some(
        (companyApp: CompanyApp) =>
          Number(companyApp.app_id) === appId &&
          companyApp.status === CompanyAppStatus.Connected
      );
    };

    const getAppOpacity = (appId: number) => {
      return isAppConnected(appId) ? 1 : 0.2;
    };

    const canSelectDepartment = (apps: App[]) => {
      return apps.some((app) => isAppConnected(Number(app.id)));
    };

    return (
      <div className="table-responsive" style={{ height: "500px" }}>
        {Object.entries(departmentMap).map(
          ([departmentId, { name, description, apps }]) => {
            const isSelected = selectedDepartmentId === Number(departmentId);

            return (
              <div
                key={departmentId}
                className={`d-flex flex-column align-items-center justify-content-center position-relative mb-4 p-3 rounded ${
                  isSelected
                    ? "border-primary border-3 border-dotted"
                    : "border-gray-300 border-dashed"
                }`}
                onClick={() => {
                  if (!canSelectDepartment(apps)) {
                    toast.error(i18n.ToastMessages.avatarDepartmentAppError, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    ReactGA.event({
                      category: "avatars_page",
                      action: "select_avatar_department",
                      label: "select_avatar_department_button",
                    });
                    return;
                  }
                  onDepartmentToggle(Number(departmentId));
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="fw-bolder fs-3">{name}</div>
                <div className="fs-7 text-muted mb-2">{description}</div>
                <div className="d-flex flex-wrap justify-content-center">
                  {apps.map((app) => (
                    <img
                      key={app.id}
                      src={app.logo}
                      alt={app.name}
                      title={app.name}
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "6px",
                        opacity: getAppOpacity(Number(app.id)),
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  });

export default DepartmentSelectionTable;
