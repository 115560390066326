// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyApp } from "../../app/models/CompanyApp";

export default async function createCompanyApp(
  app_id: number,
  company_id: number,
  credentials: string,
  status: string,
  modified_at: number,
  last_sync_at: number,
  source_ids: string[] | undefined
): Promise<CompanyApp> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const sourceIds = source_ids
      ? encodeURIComponent(JSON.stringify(source_ids))
      : "";

    const mutation = gql`
    mutation {
      createCompanyApp(
        app_id: ${app_id}
        company_id: ${company_id}
        credentials: "${credentials}"
        status: "${status}"
        modified_at: ${modified_at}
        last_sync_at: ${last_sync_at}
        source_ids: "${sourceIds}"
      ) {
        id
        app_id
        company_id
        credentials
        status
        modified_at
        last_sync_at
        source_ids
        app {
          id
          name
          site_url
          logo
        }
        company_user_apps {
          id
          user_id
          status
          app_id
          user {
            id
            email
            picture
          }
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyAppMutationResult: any) => {
        const companyApp: CompanyApp = get(
          createCompanyAppMutationResult,
          "data.createCompanyApp",
          null
        );

        if (companyApp) {
          resolve(companyApp);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
