import React from "react";
import { observer } from "mobx-react";
import { ListItems } from "../../models/ListItems";
import { CompanyApp } from "../../models/CompanyApp";
import stores from "../../stores";

interface Props {
  onCancel: () => void;
  app: number;
  onSelectedFoldersChange: (selectedFolders: string[]) => void;
  folders: ListItems[];
}

interface State {
  selectedFolders: ListItems[];
  selectedFolderIds: string[];
  openFolderIds: Map<string, boolean>;
}

@observer
export default class ListItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFolderIds: [],
      openFolderIds: new Map<string, boolean>(),
      selectedFolders: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.app !== this.props.app ||
      prevProps.folders !== this.props.folders
    ) {
      const companyApp = stores.companyAppStore.companyApps.find(
        (app) => app.app_id.toString() === this.props.app.toString()
      );

      if (companyApp) {
        const selectedFolderIds = companyApp.source_ids || [];
        const { folders } = this.props;
        const allFolders = this.flattenFolders(folders);
        const allSelectedFolderIds = this.getAllSelectedFolderIdsWithParents(
          selectedFolderIds,
          allFolders
        );
        this.setState({ selectedFolderIds: allSelectedFolderIds });
      }
    }
  }

  private getAllSelectedFolderIdsWithParents = (
    selectedFolderIds: string[],
    allFolders: ListItems[]
  ): string[] => {
    const parentChildMap = new Map<string, string[]>();

    allFolders.forEach((folder) => {
      if (folder.children) {
        folder.children.forEach((child) => {
          if (!parentChildMap.has(child.id)) {
            parentChildMap.set(child.id, []);
          }
          parentChildMap.get(child.id)!.push(folder.id);
        });
      }
    });

    const result = new Set(selectedFolderIds);

    const addParentFolders = (childId: string) => {
      if (parentChildMap.has(childId)) {
        parentChildMap.get(childId)!.forEach((parentId) => {
          const parentFolder = allFolders.find(
            (folder) => folder.id === parentId
          );
          const allChildrenSelected =
            parentFolder && parentFolder.children
              ? parentFolder.children.every((child) => result.has(child.id))
              : false;

          if (allChildrenSelected) {
            result.add(parentId);
            addParentFolders(parentId);
          }
        });
      }
    };

    selectedFolderIds.forEach((id) => {
      addParentFolders(id);
    });

    return Array.from(result);
  };

  private toggleFolderSelection = (
    folderId: string,
    isSelected: boolean,
    folders: ListItems[]
  ) => {
    const updateSelectedFolderIds = (ids: string[], add: boolean) => {
      this.setState(
        (prevState) => ({
          selectedFolderIds: add
            ? Array.from(new Set([...prevState.selectedFolderIds, ...ids]))
            : prevState.selectedFolderIds.filter((id) => !ids.includes(id)),
        }),
        () => {
          this.handleSaveSelections();
          this.updateParentSelection(folderId);
        }
      );
    };

    const allFolderIds = this.getAllFolderIds(folders);

    if (isSelected) {
      updateSelectedFolderIds(allFolderIds, true);
    } else {
      updateSelectedFolderIds(allFolderIds, false);
      this.updateParentSelectionIfNeeded(folderId);
    }
  };

  private updateParentSelection = (folderId: string) => {
    const { folders } = this.props;
    const allFolders = this.flattenFolders(folders);

    const parentFolder = allFolders.find(
      (folder) =>
        folder.children &&
        folder.children.some((child) => child.id === folderId)
    );

    if (parentFolder && parentFolder.children) {
      const allChildrenSelected = parentFolder.children.every((child) =>
        this.state.selectedFolderIds.includes(child.id)
      );

      if (
        allChildrenSelected &&
        !this.state.selectedFolderIds.includes(parentFolder.id)
      ) {
        this.setState(
          (prevState) => ({
            selectedFolderIds: [
              ...prevState.selectedFolderIds,
              parentFolder.id,
            ],
          }),
          () => this.updateParentSelection(parentFolder.id)
        );
      } else if (
        !allChildrenSelected &&
        this.state.selectedFolderIds.includes(parentFolder.id)
      ) {
        this.setState(
          (prevState) => ({
            selectedFolderIds: prevState.selectedFolderIds.filter(
              (id) => id !== parentFolder.id
            ),
          }),
          () => this.updateParentSelection(parentFolder.id)
        );
      }
    }
  };

  private updateParentSelectionIfNeeded = (childFolderId: string) => {
    const { folders } = this.props;
    const allFolders = this.flattenFolders(folders);

    const parentFolder = allFolders.find(
      (folder) =>
        folder.children &&
        folder.children.some((child) => child.id === childFolderId)
    );

    if (parentFolder) {
      this.updateParentSelection(parentFolder.id);
    }
  };

  private flattenFolders = (
    folders: ListItems[],
    acc: ListItems[] = []
  ): ListItems[] => {
    folders.forEach((folder) => {
      acc.push(folder);
      if (folder.children) {
        this.flattenFolders(folder.children, acc);
      }
    });
    return acc;
  };

  getAllFolderIds = (folders: ListItems[]): string[] => {
    let ids: string[] = [];
    folders.forEach((folder) => {
      ids.push(folder.id);
      if (folder.children) {
        ids = [...ids, ...this.getAllFolderIds(folder.children)];
      }
    });
    return ids;
  };

  private toggleFolder = (folderId: string) => {
    this.setState((prevState) => {
      const newOpenFolderIds = new Map(prevState.openFolderIds);
      newOpenFolderIds.set(folderId, !newOpenFolderIds.get(folderId));
      return { ...prevState, openFolderIds: newOpenFolderIds };
    });
  };

  private getSelectedFolders = (
    folders: ListItems[],
    selectedIds: string[]
  ): ListItems[] => {
    return folders.reduce<ListItems[]>((acc, folder) => {
      let selectedChildren = folder.children
        ? this.getSelectedFolders(folder.children, selectedIds)
        : [];

      if (selectedIds.includes(folder.id) || selectedChildren.length > 0) {
        acc.push({
          ...folder,
          children: selectedChildren,
        });
      }
      return acc;
    }, []);
  };

  handleSaveSelections = async () => {
    const { folders } = this.props;
    const companyApp = stores.companyAppStore.companyApps.find(
      (app) => app.app_id.toString() === this.props.app.toString()
    );

    if (!companyApp) {
      console.error("No matching company app found");
      return;
    }

    const selectedFolders = this.getSelectedFolders(
      folders,
      this.state.selectedFolderIds
    );

    const getFileIds = (folders: any[]): string[] => {
      let ids: string[] = [];
      folders.forEach((folder) => {
        if (folder.children && folder.children.length > 0) {
          ids = ids.concat(getFileIds(folder.children));
        } else if (folder.mimeType !== "application/vnd.google-apps.folder") {
          ids.push(folder.id);
        }
      });
      return ids;
    };

    const sourceIds = getFileIds(selectedFolders);

    const updatedCompanyApp: CompanyApp = {
      ...companyApp,
      source_ids: sourceIds || [],
    };

    try {
      await stores.companyAppStore.updateCompanyApp(updatedCompanyApp);

      this.props.onSelectedFoldersChange(sourceIds);
    } catch (error) {
      console.error("Failed to update company app:", error);
    }
  };

  private renderFolders = (
    folders: ListItems[],
    level: number = 0
  ): JSX.Element => (
    <ul className="list-unstyled">
      {folders.map((folder) => (
        <li key={folder.id} style={{ marginLeft: "20px" }}>
          <div className="d-flex align-items-center justify-content-between mb-5 ">
            <div className="d-flex align-items-center">
              {folder.children && folder.children.length > 0 && (
                <span onClick={() => this.toggleFolder(folder.id)}>
                  <i
                    className={`fa ${
                      this.state.openFolderIds.get(folder.id)
                        ? "fa-chevron-down ms-5"
                        : "fa-chevron-right ms-5"
                    } fs-2 `}
                  ></i>
                </span>
              )}

              {folder.mimeType.includes("folder") &&
              folder.children &&
              folder.children.length === 0 ? null : (
                <label className="form-check form-check-sm form-check-custom form-check-solid ms-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.selectedFolderIds.includes(folder.id)}
                    onChange={(event) =>
                      this.toggleFolderSelection(
                        folder.id,
                        event.target.checked,
                        [folder]
                      )
                    }
                  />
                </label>
              )}

              <i
                className={
                  folder.mimeType.includes("folder")
                    ? this.state.openFolderIds.get(folder.id)
                      ? "far fa-folder-open fs-2 ms-5"
                      : "far fa-folder fs-2 ms-5"
                    : "fa-regular fa-file fs-2 ms-5"
                }
              ></i>
              <span className="ms-3 fw-bold">{folder.name}</span>
            </div>
          </div>
          {this.state.openFolderIds.get(folder.id) && folder.children && (
            <div className="ms-10 mt-2">
              {this.renderFolders(folder.children, level + 1)}
            </div>
          )}
        </li>
      ))}
    </ul>
  );

  render() {
    const { folders } = this.props;

    return (
      <>
        {folders.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              minHeight: "calc(70vh - 200px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className="spinner-border text-primary"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
          </div>
        ) : (
          <div
            className="mt-7"
            style={{
              maxHeight: "calc(70vh - 200px)",
              overflowY: "auto",
            }}
          >
            <div> {this.renderFolders(folders)}</div>
          </div>
        )}
      </>
    );
  }
}
