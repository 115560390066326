// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserActionLog } from "../../app/models/UserActionLog";

export default async function createUserActionLog(
  user_id: number,
  action: string,
  action_result: string,
  action_code: number,
  action_result_code: number,
  action_status: string,
  modified_at: number
): Promise<UserActionLog> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createUserActionLog(
        user_id: ${user_id}
        action: "${action}"
        action_result: "${action_result}"
        action_code: ${action_code}
        action_result_code: ${action_result_code}
        action_status: "${action_status}"
        modified_at: ${modified_at}
      ) {
        id
        user_id
        action
        action_result
        action_code
        action_result_code
        action_status
        modified_at
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createUserActionLogMutationResult: any) => {
        const userActionLog: UserActionLog = get(
          createUserActionLogMutationResult,
          "data.createUserActionLog",
          null
        );

        if (userActionLog) {
          resolve(userActionLog);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
