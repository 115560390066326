import React, { useState, createContext, useContext, useMemo } from "react";
import { observer } from "mobx-react";

import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialUserListView,
  UserListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from "../../../../../helpers";
import stores from "../../../../stores";

const ListViewContext =
  createContext<UserListViewContextProps>(initialUserListView);

const ListViewProvider: React.FC<WithChildren> = observer(({ children }) => {
  const [selectedCompanyUserIds, setSelectedCompanyUserIds] = useState<
    Array<number>
  >(initialUserListView.selectedCompanyUserIds);
  const [itemIdForUpdate, setItemIdForUpdate] = useState<number>(
    initialUserListView.itemIdForUpdate
  );

  const disabled = useMemo(
    () =>
      calculatedGroupingIsDisabled(
        stores.companyUserStore.isLoading,
        stores.companyUserStore.companyUsers
      ),
    []
  );

  const isAllSelected = useMemo(
    () =>
      calculateIsAllDataSelected(
        stores.companyUserStore.companyUsers,
        selectedCompanyUserIds
      ),
    [selectedCompanyUserIds]
  );

  return (
    <ListViewContext.Provider
      value={{
        selectedCompanyUserIds,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: number) => {
          groupingOnSelect(
            id,
            selectedCompanyUserIds,
            setSelectedCompanyUserIds
          );
        },
        onSelectAll: () => {
          groupingOnSelectAll(
            isAllSelected,
            setSelectedCompanyUserIds,
            stores.companyUserStore.companyUsers
          );
        },
        clearSelected: () => {
          setSelectedCompanyUserIds([]);
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
});

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };
