/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

import getCompanyUserDepartments from "../../../../../../helpers/api/getCompanyUserDepartments";
import Functions from "../../../../../../helpers/Functions";
// import { toAbsoluteUrl } from "../../../../../../helpers";
import { CustomUser } from "../../../../../models/CustomUser";
import { Department } from "../../../../../models/Department";
// import { CompanyApp } from "../../../../../models/CompanyApp";
import stores from "../../../../../stores";

type Props = {
  customUser: CustomUser;
};

type State = {
  userDepartments: Department[];
  loading: boolean;
};

@observer
export class CompanyUserDepartmentsCell extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = { userDepartments: [], loading: true };
    this.initializeData();
  }

  async initializeData() {
    const { customUser } = this.props;

    if (customUser) {
      try {
        const companyUserDepartments = await getCompanyUserDepartments(
          customUser.id
        );

        const userDepartments =
          stores.companyDepartmentStore.departments.filter((department) =>
            companyUserDepartments.some(
              (companyUserDepartment) =>
                companyUserDepartment.department_id.toString() ===
                department.id.toString()
            )
          );

        this.setState({
          userDepartments: userDepartments,
          loading: false,
        });
      } catch (error) {
        console.error("Error fetching company user departments:", error);
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { userDepartments, loading } = this.state;
    const { customUser } = this.props;
    const isUserInCompanyUsers = stores.companyUserStore.companyUsers.some(
      (user) => user.id === customUser.id
    );

    if (customUser.role_id === 1 || !isUserInCompanyUsers) {
      return (
        <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
          <div> - </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      );
    }

    if (!userDepartments || userDepartments.length === 0) {
      return (
        <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
          <Link
            className="btn btn-sm btn-light"
            to={`/company-user-manage-page/${customUser.id}`}
            onClick={() =>
              ReactGA.event({
                category: "company_users",
                action: "add_company_user_departments",
                label: "add_company_user_departments_button",
              })
            }
            style={{
              width: "35px",
              height: "35px",
              padding: 0,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <i className="fas fa-plus" />
            </div>
          </Link>
        </div>
      );
    }

    return (
      <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
        {userDepartments?.slice(0, 3).map((userDepartments) => (
          <span
            key={userDepartments.id}
            style={{
              backgroundColor: Functions.lightenColor(
                userDepartments.color,
                70
              ),
              color: userDepartments.color,
              padding: "1.5px 4.5px",
              borderRadius: "3px",
              marginRight: "3px",
              marginTop: "5px",
              fontSize: "0.75em",
            }}
          >
            {userDepartments.name}
          </span>
        ))}

        {userDepartments.length > 3 ? (
          <div className="symbol symbol-circle symbol-25px mx-2 mt-2">
            <div className="symbol-label bg-primary">
              <span className="fs-8 text-inverse-dark">{`+${
                userDepartments.length - 3
              }`}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
