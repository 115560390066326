/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import Nango from "@nangohq/frontend";

import { CompanyApp } from "../../../../models/CompanyApp";
import { CompanyAppStatus } from "../../../../../helpers/Enums";
import { App } from "../../../../models/App";
import { toAbsoluteUrl } from "../../../../../helpers";
import Functions from "../../../../../helpers/Functions";
import stores from "../../../../stores";

interface State {
  teamIds: string;
}

interface Props {
  app: App;
  companyApp?: CompanyApp;
  appStatus: CompanyAppStatus;
  isActiveCard: boolean;
  isClickable: boolean;
  toggleActiveCard: (appId?: number) => void;
}

@observer
export default class FigmaCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      teamIds: "",
    };
  }

  private handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  private handleTeamIdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      teamIds: e.target.value,
    });
  };

  private handleConnectApp = async (app: App) => {
    const { teamIds } = this.state;
    const appIndex = stores.companyAppStore.companyApps.findIndex(
      (companyApp) => companyApp.app.id === app.id
    );

    if (appIndex === -1) {
      if (
        stores.companyStore.selectedUserCompany &&
        stores.companyStore.selectedUserCompany !== undefined
      ) {
        const nango = new Nango({
          //@ts-ignore
          publicKey: process.env.REACT_APP_NANGO_PUBLIC_KEY,
        });

        const connectionId = `${stores.companyStore.selectedUserCompany.id}-${app.id}`;

        this.props.toggleActiveCard(undefined);

        nango
          .auth(app.integration_id, connectionId, {
            params: { teamIds },
          })
          .then(
            async (result: {
              providerConfigKey: string;
              connectionId: string;
            }) => {
              const createdCompanyApp =
                await stores.companyAppStore.createCompanyApp(
                  app,
                  connectionId
                );

              if (createdCompanyApp.status === CompanyAppStatus.Connected)
                await this.handleSyncApp(app);
            }
          )
          .catch((err: { message: string; type: string }) => {
            console.error("error: ", err);
          });
      }
    } else {
      await stores.companyAppStore.deleteCompanyApp(
        stores.companyAppStore.companyApps[appIndex]
      );
    }
  };

  private handleSyncApp = async (app: App) => {
    const appIndex = stores.companyAppStore.companyApps.findIndex(
      (companyApp) => companyApp.app.id === app.id
    );

    if (appIndex !== -1) {
      if (
        stores.companyStore.selectedUserCompany &&
        stores.companyStore.selectedUserCompany !== undefined
      ) {
        await stores.companyAppStore.syncCompanyAppData(
          stores.companyAppStore.companyApps[appIndex]
        );
      }
    }
  };

  private renderAppStatusBadge = (appStatus: CompanyAppStatus, app: App) => {
    if (!app.published) {
      return (
        <span className="badge badge-light-warning fw-bolder">
          Coming soon..
        </span>
      );
    }

    return appStatus === CompanyAppStatus.Connected ? (
      <span className="badge badge-light-primary fw-bolder">Connected</span>
    ) : appStatus === CompanyAppStatus.InProgress ? (
      <span className="badge badge-light-warning fw-bolder">In Progress</span>
    ) : appStatus === CompanyAppStatus.Failed ? (
      <span className="badge badge-light-danger fw-bolder">
        Connection Failed
      </span>
    ) : (
      <span className="badge badge-light-danger fw-bolder">Not Connected</span>
    );
  };

  render() {
    const { app, companyApp, appStatus, isActiveCard, isClickable } =
      this.props;

    return (
      <div
        className={`col-sm-6 col-xl-4 ${!isClickable && "not-clickable"}`}
        style={{
          opacity: app.published ? 1 : 0.5,
          pointerEvents: app.published ? "auto" : "none",
        }}
        key={app.id}
      >
        <div className={`card `}>
          <div className="card-body d-flex flex-column align-items-center px-7 pt-7 ">
            <div className="w-100">
              <span
                className={`d-flex align-items-center me-2 mb-6 ${
                  isActiveCard ? "justify-content-center" : ""
                }`}
                style={{ paddingLeft: isActiveCard ? 35 : 0 }}
                key={app.id}
              >
                <div className="me-2 position-relative">
                  <div className="symbol symbol-50px me-1">
                    <span className="symbol-label">
                      <img
                        src={toAbsoluteUrl(app.logo)}
                        className="w-50 align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                </div>

                <span className="d-flex flex-column">
                  <span className="fw-bolder fs-6">{app.name}</span>

                  {isActiveCard ? null : (
                    <span className="fs-7 text-muted mt-1">
                      {this.renderAppStatusBadge(appStatus, app)}
                    </span>
                  )}
                </span>

                <span
                  className="d-flex flex-column"
                  style={{ flex: 1, alignItems: "end" }}
                >
                  {companyApp &&
                  companyApp.status !== CompanyAppStatus.Failed &&
                  companyApp.last_sync_at === 0 ? (
                    <span className="fs-8 text-warning fw-bolder">
                      Syncing..
                    </span>
                  ) : companyApp && companyApp.last_sync_at === 0 ? (
                    <span className="fs-8 text-danger fw-bolder">
                      Not Synced
                    </span>
                  ) : !companyApp ? (
                    <span className="fs-8 text-danger fw-bolder"></span>
                  ) : (
                    <span className="d-flex flex-column">
                      <span className="badge badge-light">
                        {`Last sync: ${Functions.getPastTimeInfo(
                          companyApp.last_sync_at
                        )}`}
                      </span>
                    </span>
                  )}
                </span>
              </span>

              {isActiveCard ? (
                <div className="w-100">
                  <button
                    className="btn  position-absolute"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.toggleActiveCard(undefined);
                    }}
                    style={{
                      left: "0px",
                      top: "10px",
                    }}
                  >
                    <i className="fas fa-arrow-left fs-1"></i>
                  </button>

                  <div className="d-flex justify-content-center align-items-center">
                    <i
                      className="fas fa-exclamation-circle fs-3"
                      data-bs-toggle="tooltip"
                      title="To find the value for the team id parameter used in Figma, log into your Figma account and look at the URL in your browser. You’ll see something like https://www.figma.com/files/{teamId}/team/ the {teamId} part is the team id. (ex: 1002361234911901531). You can enter more than one team id by placing a comma between them. (ex: 1002361234911901531,1012361234911901531)"
                    ></i>

                    <input
                      type="text"
                      className="mb-3 mt-3 form-control-solid form-control form-control-sm"
                      name="teamIds"
                      placeholder="Please enter team ids"
                      onChange={this.handleTeamIdsChange}
                      onClick={this.handleInputClick}
                    />
                  </div>

                  <span className="w-100 d-flex justify-content-center ">
                    <a
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        this.handleConnectApp(app);
                      }}
                    >
                      Connect
                    </a>
                  </span>
                </div>
              ) : (
                <div className="w-100">
                  <div className="w-100 d-flex justify-content-center mt-4">
                    {app.published && appStatus === CompanyAppStatus.None ? (
                      <span className="m-3">
                        <a
                          className="btn btn-primary"
                          onClick={() => {
                            this.props.toggleActiveCard(app.id);
                          }}
                        >
                          Connect
                        </a>
                      </span>
                    ) : null}

                    {(appStatus === CompanyAppStatus.Connected ||
                      appStatus === CompanyAppStatus.Failed) &&
                    companyApp?.last_sync_at !== 0 ? (
                      <span className="m-3">
                        <button
                          className="btn btn-outline"
                          onClick={() => {
                            this.handleConnectApp(app);
                          }}
                        >
                          Disconnect
                        </button>
                      </span>
                    ) : null}

                    {appStatus === CompanyAppStatus.Failed ? (
                      <span className="m-3">
                        <button
                          className="btn btn-outline"
                          onClick={() => {
                            this.handleConnectApp(app);
                          }}
                        >
                          Retry
                        </button>
                      </span>
                    ) : null}

                    {appStatus === CompanyAppStatus.Connected &&
                    companyApp?.last_sync_at !== 0 ? (
                      <span className="m-3">
                        <button
                          className={`btn btn-primary`}
                          style={{ width: 130 }}
                          onClick={() => {
                            this.handleSyncApp(app);
                          }}
                        >
                          Sync Data
                        </button>
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
