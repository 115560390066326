/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserRole } from "../../app/models/UserRole";

export default async function getUserRoles(): Promise<UserRole[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserRolesQuery {
        roles {
          id
          name
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((UserRolesQueryResult: any) => {
        const userRoles: UserRole[] = get(
          UserRolesQueryResult,
          "data.roles",
          null
        );

        resolve(userRoles);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
