/* @flow */

export type DeleteAppDataResult = {
  success: boolean;
  message: string;
};

export default async function deleteAppData(
  companyId: number,
  companyAppId: number,
  companyStorageId: number
): Promise<DeleteAppDataResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/deleteAppData`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyId: companyId,
        companyAppId: companyAppId,
        companyStorageId: companyStorageId,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: DeleteAppDataResult = data;

          resolve(result);
        } else {
          reject();
        }
      });
  });
}
