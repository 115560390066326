import { action, observable, when } from "mobx";
import { toast } from "react-toastify";

import {
  checkNangoAppCredentials,
  createCompanyApp,
  createCompanyUserApp,
  deleteAppData,
  deleteCompanyApp,
  deleteCompanyUserApp,
  getAppDepartments,
  getApps,
  getCompanyApps,
  updateCompanyApp,
} from "../../helpers/api";
import { CompanyApp } from "../models/CompanyApp";
import {
  CompanyAppStatus,
  CompanyUserAppStatus,
  UserActionLogStatus,
} from "../../helpers/Enums";
import { CompanyUser } from "../models/CompanyUser";
import { App } from "../models/App";
import { CompanyUserApp } from "../models/CompanyUserApp";
import { AppDepartment } from "../models/AppDepartment";
import i18n from "../../i18n";
import syncAppData from "../../helpers/api/syncAppData";
import SocketHelper from "../../helpers/SocketHelper";

import RootStore from "./RootStore";
import stores from ".";

export type CompanyAppFilter = {
  status: CompanyAppStatus;
};

export default class CompanyAppStore {
  @observable apps: App[] = [];
  @observable.deep companyApps: CompanyApp[] = [];
  @observable.deep nonFilteredCompanyApps: CompanyApp[] = [];
  @observable appDepartments: AppDepartment[] = [];
  @observable selectedCompanyApp?: CompanyApp;
  @observable selectedCompanyApps: CompanyApp[] = [];
  @observable isConnectAppModalOpened: boolean = false;
  @observable isCompanyAppEditModalOpened: boolean = false;
  @observable isCompanyAppImportUsersModalOpened: boolean = false;
  @observable isCompanyAppAddUserModalOpened: boolean = false;
  @observable isAddCompanyAppsToUsersModalOpened: boolean = false;
  @observable isAddAppModalOpened: boolean = false;
  @observable isLoading: boolean = true;
  @observable appParamsModalRef: any;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        !rootStore.companyStorageStore.isLoading,
      async () => {
        await this.getCompanyApps();
        await this.getAppDepartments();

        SocketHelper.addMessageHandler(this.handleMessage);
      }
    );
  }

  private handleMessage = async (data: any) => {
    if (data.companyAppId) {
      const companyApp = this.companyApps.find(
        (item) => item.id === data.companyAppId
      );

      if (data.processId === 2) {
        if (companyApp && data.isSyncComplete && data.isCreateIndexComplete) {
          companyApp.last_sync_at = Date.now();

          await updateCompanyApp(companyApp);

          await stores.userActionLogStore.createUserActionLog(
            `${companyApp.app.name} data index created.`,
            "",
            0,
            0,
            UserActionLogStatus.Success
          );

          toast.update(companyApp.id, {
            render: `${companyApp.app.name} data index created.`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else if (companyApp) {
          await stores.userActionLogStore.createUserActionLog(
            `${companyApp.app.name} data sync error: ${data.syncDataMessage} - ${data.createIndexMessage}`,
            "",
            0,
            0,
            UserActionLogStatus.Declined
          );

          companyApp.last_sync_at = 0;
          companyApp.status = CompanyAppStatus.Failed;

          await updateCompanyApp(companyApp);

          toast.update(companyApp.id, {
            render: `${companyApp.app.name} data sync error`,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
      } else if (data.processId === 3) {
        if (companyApp && data.status) {
          toast.update(companyApp.id, {
            render: `${companyApp.app.name}: ${data.status.uploadedFilesLength} file synced, ${data.status.notUploadedFilesLength} file not synced`,
          });
        }
      } else if (data.processId === 4) {
        if (companyApp && data.message) {
          toast.update(companyApp.id, {
            render: `${companyApp.app.name}: ${data.message}`,
          });
        }
      } else if (data.processId === 5) {
        if (data.isDataDeletionComplete && data.isIndexDeletionComplete) {
          await stores.userActionLogStore.createUserActionLog(
            `${data.appName} data index deleted.`,
            "",
            0,
            0,
            UserActionLogStatus.Success
          );

          toast.update(`${data.companyAppId}`, {
            render: `${data.appName} data index deleted.`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        }
      }
    }
  };

  @action getCompanyApps = async () => {
    this.isLoading = true;

    this.apps = await getApps();

    this.nonFilteredCompanyApps = [];
    this.companyApps = [];

    if (stores.companyStore.selectedUserCompany !== undefined) {
      this.nonFilteredCompanyApps = await getCompanyApps(
        stores.companyStore.selectedUserCompany.id
      );
    }

    this.filterAndSortCompanyApps();

    this.isLoading = false;
  };

  @action findCompanyAppById = (
    companyAppId: number
  ): CompanyApp | undefined => {
    const appIdStr = companyAppId.toString();
    const companyApp = this.companyApps.find(
      (app) => app.id.toString() === appIdStr
    );
    return companyApp;
  };

  @action findAppById = (appId: number): App | undefined => {
    const appIdStr = appId.toString();
    const app = this.apps.find((app) => app.id.toString() === appIdStr);
    return app;
  };

  @action filterAndSortCompanyApps = (
    searchTerm?: string,
    filter?: CompanyAppFilter
  ) => {
    this.selectedCompanyApp = undefined;
    this.selectedCompanyApps = [];

    this.companyApps = this.nonFilteredCompanyApps;

    if (searchTerm)
      this.companyApps = this.nonFilteredCompanyApps.filter(
        (companyApp) =>
          companyApp.company_user_apps.findIndex((item) =>
            item.user.name?.includes(searchTerm)
          ) !== 1
      );

    if (filter) {
      this.companyApps = this.nonFilteredCompanyApps.filter(
        (companyApp) => companyApp.status === filter.status
      );
    }
  };

  @action createCompanyApp(
    app: App,
    credentials: string = "",
    sourceIds: string[] | undefined = undefined
  ): Promise<CompanyApp> {
    return new Promise(async (resolve, reject) => {
      // Commenting out the permission check for connecting an app
      // if (
      //   !(await stores.userStore.checkSubscribedFeatureType(
      //     FeatureType.ConnectApp
      //   ))
      // ) {
      //   reject();
      //   return;
      // }

      if (!stores.companyStore.selectedUserCompany) {
        reject();
        return;
      }

      const actionToastid = toast.loading(`${app.name} connection pending`, {
        autoClose: 5000,
        position: "top-right",
      });

      const userActionLog = await stores.userActionLogStore.createUserActionLog(
        `${app.name} connection`,
        "",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const createdCompanyApp = await createCompanyApp(
        app.id,
        stores.companyStore.selectedUserCompany.id,
        credentials,
        CompanyAppStatus.InProgress,
        Date.now(),
        0,
        sourceIds
      );

      this.nonFilteredCompanyApps.push(createdCompanyApp);

      const checkNangoAppCredentialsResult = await checkNangoAppCredentials(
        createdCompanyApp.id
      );

      const companyAppIndex = this.nonFilteredCompanyApps.findIndex(
        (item) => item.id === createdCompanyApp.id
      );

      this.nonFilteredCompanyApps[companyAppIndex].status =
        checkNangoAppCredentialsResult.success
          ? CompanyAppStatus.Connected
          : CompanyAppStatus.Failed;

      this.nonFilteredCompanyApps[companyAppIndex].modified_at = Date.now();

      await stores.companyAppStore.updateCompanyApp(
        this.nonFilteredCompanyApps[companyAppIndex]
      );

      userActionLog.action_status = checkNangoAppCredentialsResult.success
        ? UserActionLogStatus.Success
        : UserActionLogStatus.Declined;
      userActionLog.action_result = checkNangoAppCredentialsResult.success
        ? `${app.name} connection successful.`
        : `${app.name} connection error: ${checkNangoAppCredentialsResult.message}`;

      stores.userActionLogStore.updateUserActionLog(userActionLog);

      toast.update(actionToastid, {
        render: checkNangoAppCredentialsResult.success
          ? `${app.name} connection successful.`
          : `${app.name} connection error`,
        type: checkNangoAppCredentialsResult.success ? "success" : "error",
        isLoading: false,
        autoClose: 3000,
      });

      resolve(this.nonFilteredCompanyApps[companyAppIndex]);
    });
  }

  @action syncCompanyAppData(companyApp: CompanyApp): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        toast.error(i18n.ToastMessages.nonStorageError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      const actionToastid = toast.loading(
        `${companyApp.app.name} data syncing`,
        {
          position: "top-right",
          autoClose: 5000,
          toastId: companyApp.id,
        }
      );

      const userActionLog = await stores.userActionLogStore.createUserActionLog(
        `${companyApp.app.name} data sync starting`,
        "",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const updatedCompanyApp = companyApp;
      updatedCompanyApp.last_sync_at = 0;
      updatedCompanyApp.modified_at = 0;
      await updateCompanyApp(companyApp);

      const syncAppDataResult = await syncAppData(
        companyApp.company_id,
        companyApp.id,
        stores.companyStorageStore.selectedCompanyStorage.id
      );

      if (syncAppDataResult.success) {
        userActionLog.action_status = UserActionLogStatus.Success;
        userActionLog.action_result = `${companyApp.app.name} data sync started`;

        stores.userActionLogStore.updateUserActionLog(userActionLog);

        resolve(true);
      } else {
        userActionLog.action_status = UserActionLogStatus.Declined;
        userActionLog.action_result = `${companyApp.app.name} data sync error: ${syncAppDataResult.message}`;

        stores.userActionLogStore.updateUserActionLog(userActionLog);

        toast.update(actionToastid, {
          render: `${companyApp.app.name} data sync error`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });

        resolve(false);
      }
    });
  }

  @action updateCompanyApp(companyApp: CompanyApp): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateCompanyApp(companyApp);

      const existingCompanyAppIndex = this.nonFilteredCompanyApps.findIndex(
        (item) => item.id === companyApp.id
      );

      if (existingCompanyAppIndex !== -1) {
        this.nonFilteredCompanyApps[existingCompanyAppIndex] = companyApp;
      }

      this.filterAndSortCompanyApps();

      resolve(true);
    });
  }

  @action deleteCompanyApp(companyApp: CompanyApp): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        toast.error(i18n.ToastMessages.nonStorageError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      toast.loading(`${companyApp.app.name} data is deleting`, {
        position: "top-right",
        autoClose: 5000,
        toastId: companyApp.id,
      });

      await deleteAppData(
        companyApp.company_id,
        companyApp.id,
        stores.companyStorageStore.selectedCompanyStorage.id
      );

      this.nonFilteredCompanyApps = this.nonFilteredCompanyApps.filter(
        (item) => item.id !== companyApp.id
      );

      this.filterAndSortCompanyApps();

      resolve(true);
    });
  }

  @action deleteSelectedCompanyApps(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.selectedCompanyApps.forEach(async (companyApp) => {
        await deleteCompanyApp(companyApp.id);

        this.nonFilteredCompanyApps = this.nonFilteredCompanyApps.filter(
          (item) => item.id !== companyApp.id
        );

        this.filterAndSortCompanyApps();

        this.selectedCompanyApps = this.selectedCompanyApps.filter(
          (item) => item.id !== companyApp.id
        );
      });

      resolve(true);
    });
  }

  @action addCompanyUsersToApps(
    selectedCompanyApps: CompanyApp[],
    selectedUsers: CompanyUser[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < selectedCompanyApps.length; index++) {
        const companyApp = selectedCompanyApps[index];

        for (let index = 0; index < selectedUsers.length; index++) {
          const companyUser = selectedUsers[index];

          if (
            companyApp.company_user_apps.findIndex(
              (item) =>
                item.app_id.toString() === companyApp.app_id.toString() &&
                item.user_id === companyUser.user_id
            ) === -1
          ) {
            if (companyUser.user.email) {
              const companyAppIndex = this.nonFilteredCompanyApps.findIndex(
                (item) => item.id === companyApp.id
              );

              if (companyAppIndex !== -1) {
                const companyUserApp = await createCompanyUserApp(
                  companyUser.user_id,
                  companyApp.app_id,
                  companyApp.id,
                  "",
                  CompanyUserAppStatus.InProgress,
                  Date.now()
                );

                this.nonFilteredCompanyApps[
                  companyAppIndex
                ].company_user_apps.push(companyUserApp);
              }
            }
          } else {
            toast.error(i18n.ToastMessages.userAlreadyInvitedError, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            resolve(false);
          }
        }

        this.filterAndSortCompanyApps();
      }

      resolve(true);
    });
  }

  @action deleteCompanyUserApps(
    companyUserApps: CompanyUserApp[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < companyUserApps.length; index++) {
        const app = companyUserApps[index];

        await deleteCompanyUserApp(app.id);

        this.getCompanyApps();
      }

      resolve(true);
    });
  }

  @action getAppDepartments = async () => {
    this.appDepartments = await getAppDepartments();
  };
}
