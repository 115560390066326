/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from "react-router-dom";
import clsx from "clsx";
import { observer } from "mobx-react";

import {
  // defaultAlerts,
  // defaultLogs,
  // KTIcon,
  toAbsoluteUrl,
  // useIllustrationsPath,
} from "../../../helpers";
import stores from "../../stores";
import { UserActionLogStatus } from "../../../helpers/Enums";
import Functions from "../../../helpers/Functions";

type Props = {
  backgroundUrl: string;
};

interface State {
  currentTab: number;
}

@observer
export default class HeaderNotificationsMenu extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentTab: 2,
    };
  }

  render() {
    const { backgroundUrl } = this.props;
    // const { currentTab } = this.state;

    return (
      <div
        className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
        data-kt-menu="true"
      >
        <div
          className="d-flex flex-column bgi-no-repeat rounded-top"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(backgroundUrl)}')`,
          }}
        >
          <h3 className="text-white fw-bold px-9 mt-10 mb-6">
            Info
            {/* <span className="fs-8 opacity-75 ps-3">24 reports</span> */}
          </h3>

          <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
            {/* <li className="nav-item">
              <button
                className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                data-bs-toggle="tab"
                onClick={() => this.setState({ currentTab: 1 })}
              >
                Alerts
              </button>
            </li>

            <li className="nav-item">
              <button
                className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                data-bs-toggle="tab"
                onClick={() => this.setState({ currentTab: 2 })}
              >
                Subscription
              </button>
            </li> */}

            <li className="nav-item">
              <button
                className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                data-bs-toggle="tab"
                onClick={() => this.setState({ currentTab: 3 })}
              >
                Logs
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          {/* {currentTab === 1 ? (
            <div
              className="tab-pane fade"
              id="kt_topbar_notifications_1"
              role="tabpanel"
            >
              <div className="scroll-y mh-325px my-5 px-8">
                {defaultAlerts.map((alert, index) => (
                  <div key={`alert${index}`} className="d-flex flex-stack py-4">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-35px me-4">
                        <span
                          className={clsx(
                            "symbol-label",
                            `bg-light-${alert.state}`
                          )}
                        >
                          {" "}
                          <KTIcon
                            iconName={alert.icon}
                            className={`fs-2 text-${alert.state}`}
                          />
                        </span>
                      </div>

                      <div className="mb-0 me-2">
                        <a className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                          {alert.title}
                        </a>
                        <div className="text-gray-400 fs-7">
                          {alert.description}
                        </div>
                      </div>
                    </div>

                    <span className="badge badge-light fs-8">{alert.time}</span>
                  </div>
                ))}
              </div>

              <div className="py-3 text-center border-top">
                <Link
                  to="/crafted/pages/profile"
                  className="btn btn-color-gray-600 btn-active-color-primary"
                >
                  View All <KTIcon iconName="arrow-right" className="fs-5" />
                </Link>
              </div>
            </div>
          ) : null}

          {currentTab === 2 ? (
            <div
              className="tab-pane fade show active"
              id="kt_topbar_notifications_2"
              role="tabpanel"
            >
              <div className="d-flex flex-column px-9">
                <div className="pt-10 pb-0">
                  <h3 className="text-dark text-center fw-bolder">
                    Get Pro Membership
                  </h3>

                  <div className="text-center text-gray-600 fw-bold pt-1">
                    You can find all the changes you want to make regarding the
                    payment method and membership
                  </div>

                  <div className="text-center mt-5 mb-9">
                    <button
                      className="btn btn-sm btn-primary px-6"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_upgrade_plan"
                    >
                      Edit Your Subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}

          {/* {currentTab === 3 ? ( */}
          <div
            className="tab-pane fade show active"
            id="kt_topbar_notifications_3"
            role="tabpanel"
          >
            <div className="scroll-y mh-325px my-5 px-8">
              {stores.userActionLogStore.userActionLogs
                .reverse()
                .map((log, index) => (
                  <div key={`log${index}`} className="d-flex flex-stack py-4">
                    <div className="d-flex flex-grow-1 align-items-center me-2">
                      <span
                        className={clsx(
                          "w-70px badge",
                          `badge-light-${
                            log.action_status === UserActionLogStatus.Success
                              ? "success"
                              : UserActionLogStatus.Declined
                              ? "danger"
                              : "warning"
                          }`,
                          "me-4"
                        )}
                      >
                        {log.action_status === UserActionLogStatus.Success
                          ? "200 OK"
                          : UserActionLogStatus.Declined
                          ? "500 ERR"
                          : "300 WRN"}
                      </span>

                      <a className="text-gray-800 text-hover-primary fw-bold w-100">
                        {log.action}
                      </a>

                      <span className="badge badge-light fs-8">
                        {Functions.getPastTimeInfo(log.modified_at)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>

            {/* <div className="py-3 text-center border-top">
                <Link
                  to="/crafted/pages/profile"
                  className="btn btn-color-gray-600 btn-active-color-primary"
                >
                  View All <KTIcon iconName="arrow-right" className="fs-5" />
                </Link>
              </div> */}
          </div>
          {/* ) : null} */}
        </div>
      </div>
    );
  }
}
