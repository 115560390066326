import React from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

import i18n from "../../../../../../i18n";
import { KTIcon } from "../../../../../../helpers";
import stores from "../../../../../stores";
import { FeatureType } from "../../../../../../helpers/Enums";
import { CompanyUsersListFilter } from "./CompanyUsersListFilter";

export const CompanyUsersListToolbar: React.FC = () => {
  const openAddUserModal = async () => {
    ReactGA.event({
      category: "company_users",
      action: "invite_new_user",
      label: "invite_new_user_button",
    });
    const canAddNewUser = await stores.userStore.checkSubscribedFeatureType(
      FeatureType.CompanyUser
    );

    const isCompanyStorageConnected =
      stores.companyStorageStore.companyStorages.length > 0;
    const hasCompanyModel = stores.companyModelStore.companyModels.length > 0;
    const hasCompanyApp = stores.companyAppStore.companyApps.length > 0;

    if (!canAddNewUser) {
      toast.error(i18n.ToastMessages.maxCompanyUserIssue, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!isCompanyStorageConnected || !hasCompanyModel || !hasCompanyApp) {
      toast.error(i18n.ToastMessages.requiredConnectionsIssue, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    stores.companyUserStore.isCompanyUserEditModalOpened = true;
  };

  return (
    <div>
      <div
        className="d-flex justify-content-end"
        data-kt-user-table-toolbar="base"
      >
        <CompanyUsersListFilter />

        <button
          type="button"
          className="btn btn-primary"
          onClick={openAddUserModal}
        >
          <KTIcon iconName="plus" className="fs-2" />
          {i18n.CompanyUsersListToolbar.addNewUser}
        </button>
      </div>
    </div>
  );
};
