/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { FC } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";

import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import stores from "../../../app/stores";

const adminTabs: ReadonlyArray<{
  link: string;
  icon: string;
  tooltip: string;
}> = [
  {
    link: "chat",
    icon: "media/icons/duotune/communication/com007.svg",
    tooltip: "Chat",
  },
  {
    link: "connections",
    icon: "media/icons/duotune/general/gen008.svg",
    tooltip: "Connections",
  },
  {
    link: "models",
    icon: "media/icons/duotune/technology/teh003.svg",
    tooltip: "Models",
  },
  {
    link: "avatars",
    icon: "media/icons/duotune/technology/teh002.svg",
    tooltip: "Avatars",
  },
  {
    link: "storages",
    icon: "media/icons/duotune/graphs/gra010.svg",
    tooltip: "Storages",
  },
  {
    link: "company-users",
    icon: "media/icons/duotune/communication/com014.svg",
    tooltip: "Users",
  },
];

const memberTabs: ReadonlyArray<{
  link: string;
  icon: string;
  tooltip: string;
}> = [
  // {
  //   link: "dashboard",
  //   icon: "/media/icons/duotune/general/gen001.svg",
  //   tooltip: "Dashboard",
  // },
  // {
  //   link: "chat",
  //   icon: "/media/icons/duotune/communication/com007.svg",
  //   tooltip: "Chat",
  // },
  // {
  //   link: "avatars",
  //   icon: "media/icons/duotune/technology/teh002.svg",
  //   tooltip: "Avatars",
  // },
];

type Props = {
  link: string;
  setLink: (link: string) => void;
};

const AsideTabs: FC<Props> = observer(({ link, setLink }) => {
  const location = useLocation();
  const currentLink = location.pathname.slice(1);

  return (
    <div
      className="hover-scroll-y mb-10"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-wrappers="#kt_aside_nav"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-offset="0px"
    >
      {/* begin::Nav */}
      <ul className="nav flex-column" id="kt_aside_nav_tabs">
        {/* begin::Nav item */}
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10"
          id="kt_aside_logo"
        >
          <Link to="/">
            <img
              src={toAbsoluteUrl(
                "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/b4d98b10-c21b-4560-d177-28f002687c00/mid"
              )}
              alt="logo"
              className="theme-dark-show h-40px"
            />
            <img
              src={toAbsoluteUrl(
                "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/6c78f836-e585-45cf-0ddd-85f496547500/mid"
              )}
              alt="logo"
              className="theme-light-show h-40px"
            />
          </Link>
        </div>

        {stores.userStore.isCurrentUserAdmin
          ? adminTabs.map((t) => (
              <li key={t.link}>
                {/* begin::Nav link */}
                <a
                  className={clsx(
                    "mt-4 nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
                    { active: t.link === currentLink }
                  )}
                  onClick={() => setLink(t.link)}
                >
                  <KTSVG path={t.icon} className="svg-icon svg-icon-2x" />
                </a>
                {/* end::Nav link */}
              </li>
            ))
          : memberTabs.map((t) => (
              <li key={t.link}>
                {/* begin::Nav link */}
                <a
                  className={clsx(
                    "mt-4 nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
                    { active: t.link === currentLink }
                  )}
                  onClick={() => setLink(t.link)}
                >
                  <KTSVG path={t.icon} className="svg-icon svg-icon-2x" />
                </a>
                {/* end::Nav link */}
              </li>
            ))}
        {}
        {/* end::Nav link */}
      </ul>
      {/* end::Tabs */}
    </div>
  );
});

export { AsideTabs };
