import React from "react";
import { observer } from "mobx-react";

import { KTIcon } from "../../../../../helpers";
import stores from "../../../../stores";

export const CompanyUserEditModalHeader: React.FC = observer(() => {
  const closeEditModal = () => {
    stores.companyUserStore.selectedCompanyUser = undefined;
    stores.companyUserStore.isCompanyUserEditModalOpened = false;
  };

  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      <h2 className="fw-bolder">
        {stores.companyUserStore.selectedCompanyUser ? "Edit User" : "Add User"}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={closeEditModal}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
      {/* end::Close */}
    </div>
  );
});
