import { Formik, Form } from "formik";
import * as Yup from "yup";
import { WizardContent } from "./WizardComponents";
import CompanyDetailsForm from "./CompanyDetailsForm";
import stores from "../../../stores"; // Assuming you are using MobX or similar state management

interface CompanyDetailsProps {
  onNext: () => void;
  onBack: () => void;
  formData: {
    teamSize: string;
    name: string;
    logo: File | null;
    industry: string;
  };
  setFormData: (data: any) => void;
}

const CompanyDetails = ({
  onNext,
  onBack,
  formData,
  setFormData,
}: CompanyDetailsProps) => {
  // Function to create company after form submission
  const handleCreateCompany = async (values: any) => {
    try {
      // Create company using form data
      await stores.companyStore.createCompany(
        values.name,
        values.logo || "", // Handle the case where no logo is uploaded
        values.teamSize,
        values.industry,
        "Free" // This is the free plan
      );
      onNext(); // Proceed to the next step after successful creation
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validationSchema={Yup.object({
        teamSize: Yup.string().required("Team size is required"),
        name: Yup.string().required("Company name is required"),
        logo: Yup.mixed().nullable(),
        industry: Yup.string().required("Company type is required"),
        companyDescription: Yup.string().nullable(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setFormData(values); // Update the formData state with form values
        await handleCreateCompany(values); // Create company with updated values
        setSubmitting(false);
      }}
    >
      {({ isValid, dirty }) => (
        <Form
          className="form w-lg-500px mx-auto"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <WizardContent>
            <div style={{ paddingBottom: "80px" }}>
              <CompanyDetailsForm />
            </div>
          </WizardContent>
          <div
            className="fixed-bottom bg-white pb-20 pt-10"
            style={{ height: "80px" }}
          >
            <div className="container d-flex justify-content-between w-50">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onBack}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isValid || !dirty} // Button disabled until the form is valid and dirty
              >
                Create Company {/* Button to submit form and create company */}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyDetails;
