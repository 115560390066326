/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUser } from "../../app/models/CompanyUser";

export default async function getCompanyUsers(
  company_id: number
): Promise<CompanyUser[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyUsersQuery($company_id: ID!) {
        companyUsers(company_id: $company_id) {
          id
          user_id
          company_id
          role_id
          status
          user {
            id
            auth0_id
            picture
            name
            email
            email_verified
            gender
            locale
            phone_number
            phone_number_verified
            settings
            is_onboarded
            created_at
          }
          user_role {
            id
            name
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyUsersQueryResult: any) => {
        const companyUsers: CompanyUser[] = get(
          companyUsersQueryResult,
          "data.companyUsers",
          null
        );

        resolve(companyUsers);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
