/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUser } from "../../app/models/CompanyUser";

export default async function updateCompanyUser(
  companyUser: CompanyUser
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateCompanyUser(
        id: ${companyUser.id}
        user_id: ${companyUser.user_id}
        company_id: ${companyUser.company_id}
        role_id: ${companyUser.role_id}
        status: "${companyUser.status}"
      ) 
    }
  `;

  const updateCompanyUserMutationResult = await client.mutate({ mutation });

  const result = get(
    updateCompanyUserMutationResult,
    "data.updateCompanyUser",
    null
  );

  return result !== null && result[0] > 0;
}
