import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { DocumentMessage } from "../app/models/DocumentMessage";
import { UserDocument } from "../app/models/UserDocument";

const exportDocxFile = async (
  messages: DocumentMessage[],
  document: UserDocument
) => {
  if (!messages || !Array.isArray(messages) || messages.length === 0) {
    console.error("Messages data is missing or invalid.");
    return;
  }

  if (!document || !document.name) {
    console.error("Document data is missing or invalid.");
    return;
  }

  let paragraphs = messages
    .map((message, index) => {
      const userMessage = message.message
        ? decodeURIComponent(message.message)
        : "No message";
      const replyMessage = message.message_reply
        ? decodeURIComponent(message.message_reply)
        : "No reply";

      const companyName =
        message.company_app &&
        message.company_app.app &&
        message.company_app.app.name
          ? message.company_app.app.name
          : message.company_avatar && message.company_avatar.name
          ? message.company_avatar.name
          : "The Chief";

      return [
        new Paragraph({
          children: [
            new TextRun({ text: "User:\n", bold: true }),
            new TextRun(userMessage),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: `${companyName}:\n`, bold: true }),
            new TextRun(replyMessage),
          ],
        }),
        new Paragraph({
          text: "", // Boş bir paragraf ekleyerek satır arası boşluk bırakıyoruz
        }),
      ];
    })
    .flat();

  const doc = new Document({
    sections: [
      {
        properties: {},
        children: paragraphs,
      },
    ],
  });

  const fileName = decodeURIComponent(document.name);

  try {
    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${fileName}.docx`);
  } catch (error) {
    console.error("Error generating DOCX:", error);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  exportDocxFile,
};
