import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import getAppDepartments from "../../../helpers/api/getAppDepartments";
import DepartmentSelectionTable from "./DepartmentSelectionTable";
import { AppDepartment } from "../../models/AppDepartment";
import { KTIcon } from "../../../helpers";
import { App } from "../../models/App";
import stores from "../../stores";

interface DepartmentSelectionStepProps {
  onNext: () => void;
  onBack: () => void;
  setSelectedDepartmentId: (id: number) => void;
}

const DepartmentSelectionStep: React.FC<DepartmentSelectionStepProps> =
  observer(({ onNext, onBack, setSelectedDepartmentId }) => {
    const {
      selectedDepartmentId,
      setSelectedDepartment,
      isEditMode,
      editingAvatar,
    } = stores.wizardStore;
    const [appDepartments, setAppDepartments] = useState<AppDepartment[]>([]);
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(
      selectedDepartmentId === null
    );
    const [searchText, setSearchText] = useState<string>("");
    const [departmentMap, setDepartmentMap] = useState<{
      [key: number]: { name: string; description?: string; apps: App[] };
    }>({});

    useEffect(() => {
      async function fetchAppDepartments() {
        try {
          const data = await getAppDepartments();
          setAppDepartments(data);
        } catch (error) {
          console.error("Failed to fetch app departments", error);
        }
      }

      fetchAppDepartments();
    }, []);

    useEffect(() => {
      if (isEditMode && editingAvatar?.company_avatar_departments) {
        const departmentId =
          editingAvatar.company_avatar_departments[0].department_id;
        setSelectedDepartment(departmentId);
        setSelectedDepartmentId(departmentId);
        setIsNextDisabled(departmentId === null);
      }
    }, [
      isEditMode,
      editingAvatar,
      setSelectedDepartment,
      setSelectedDepartmentId,
    ]);

    useEffect(() => {
      const map: {
        [key: number]: { name: string; description?: string; apps: App[] };
      } = {};

      appDepartments.forEach(({ department, app }) => {
        if (!map[department.id]) {
          map[department.id] = {
            name: department.name,
            description: department.description,
            apps: [],
          };
        }
        map[department.id].apps.push(app);
      });

      setDepartmentMap(map);
    }, [appDepartments]);

    const handleDepartmentToggle = (departmentId: number) => {
      setSelectedDepartment(departmentId);
      setSelectedDepartmentId(departmentId);
      setIsNextDisabled(departmentId === null);
    };

    const handleNext = () => {
      if (!isNextDisabled) {
        ReactGA.event({
          category: "avatars_page",
          action: "next_select_avatar_department",
          label: "next_select_avatar_department_button",
        });
        onNext();
      }
    };

    const filteredDepartmentMap = Object.fromEntries(
      Object.entries(departmentMap).filter(([id, department]) => {
        const departmentMatches = department.name
          .toLowerCase()
          .includes(searchText.toLowerCase());

        const appMatches = department.apps.some((app) =>
          app.name.toLowerCase().includes(searchText.toLowerCase())
        );

        return departmentMatches || appMatches;
      })
    );

    return (
      <div style={{ height: "auto", overflowY: "auto", position: "relative" }}>
        <div className="w-100 mt-15 mb-5">
          <h4 className="fw-bold fs-2">Select Department</h4>
          <p className="text-muted fs-6">
            Select the department you want to manage.
          </p>
        </div>

        <div className="d-flex align-items-center justify-content-start mb-4 ">
          <KTIcon
            iconName="magnifier"
            className="fs-1 position-absolute px-3"
          />
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control border-0 w-300px ps-12 "
            placeholder="Search Department or App..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <DepartmentSelectionTable
          selectedDepartmentId={selectedDepartmentId}
          onDepartmentToggle={handleDepartmentToggle}
          departmentMap={filteredDepartmentMap}
        />
        <div style={{ textAlign: "right", marginTop: "40px" }}>
          <button
            className="btn btn-primary"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            Next
          </button>
        </div>
      </div>
    );
  });

export default DepartmentSelectionStep;
