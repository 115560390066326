import { useLayout } from "../layout/core";
import { ThemeModeComponent } from "../assets/ts/layout";

export const toAbsoluteUrl = (pathname: string) => {
  if (process.env.REACT_APP_IS_DEV === "true") {
    return pathname;
  } else {
    if (
      pathname.includes("http") === true ||
      pathname.includes("https") === true ||
      pathname.includes("localhost") === true
    ) {
      return pathname;
    } else if (window.electronAPIs !== undefined) {
      //@ts-ignore
      const sourcePath: string = window.electronAPIs.joinPath(
        //@ts-ignore
        window.electronAPIs.getDirname(),
        pathname
      );

      return sourcePath.replaceAll("/electron/", "/");
    } else {
      return pathname;
    }
  }
};

export const useIllustrationsPath = (illustrationName: string): string => {
  const { config } = useLayout();

  const extension = illustrationName.substring(
    illustrationName.lastIndexOf("."),
    illustrationName.length
  );
  const illustration =
    ThemeModeComponent.getMode() === "dark"
      ? `${illustrationName.substring(
          0,
          illustrationName.lastIndexOf(".")
        )}-dark`
      : illustrationName.substring(0, illustrationName.lastIndexOf("."));
  return toAbsoluteUrl(
    `/media/illustrations/${config.illustrations?.set}/${illustration}${extension}`
  );
};
