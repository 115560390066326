/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserInvite } from "../../app/models/CompanyUserInvite";

export default async function getInvitedUser(
  email: string
): Promise<CompanyUserInvite | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query CompanyUserInviteQuery($email: String!) {
        companyUserInvite(email: $email) {
          id
          name
          email
          company_id
          role_id
          status
          invited_at
          company {
            id
            user_id
            name
            logo
            created_at
          }
          user_role {
            id
            name
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          email: email,
        },
        fetchPolicy: "no-cache",
      })
      .then((companyUserInviteQueryResult: any) => {
        const invitedUser: CompanyUserInvite = get(
          companyUserInviteQueryResult,
          "data.companyUserInvite",
          null
        );

        if (invitedUser) {
          resolve(invitedUser);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
