import React from "react";
import { observer } from "mobx-react";

import stores from "../../../../../stores";

import { CompanyUsersListToolbar } from "./CompanyUsersListToolbar";
import { CompanyUserListGrouping } from "./CompanyUserListGrouping";
import { CompanyUsersListSearchComponent } from "./CompanyUsersListSearchComponent";

export const CompanyUsersListHeader: React.FC = observer(() => {
  return (
    <div className="card-header border-0 pt-6">
      {" "}
      <CompanyUsersListSearchComponent />
      {/* begin::Card toolbar */}
      <div className="card-toolbar">
        {/* begin::Group actions */}
        {stores.companyUserStore.selectedCompanyUsers.length > 0 ? (
          <CompanyUserListGrouping />
        ) : (
          <CompanyUsersListToolbar />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
});
