/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserActionLog } from "../../app/models/UserActionLog";

export default async function getUserActionLogs(
  user_id: number
): Promise<UserActionLog[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserActionLogsQuery($user_id: ID!) {
        userActionLogs(user_id: $user_id) {
          id
          user_id
          action
          action_result
          action_code
          action_result_code
          action_status
          modified_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          user_id: user_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((userActionLogsQueryResult: any) => {
        const userActionLogs: UserActionLog[] = get(
          userActionLogsQueryResult,
          "data.userActionLogs",
          null
        );

        resolve(userActionLogs);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
