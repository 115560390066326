/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Company } from "../../app/models/Company";

export default async function getUserCompanies(
  user_id: number
): Promise<Company[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserCompaniesQuery($user_id: ID!) {
        userCompanies(user_id: $user_id) {
          id
          user_id
          name
          logo
          created_at
          teamSize
          industry
          plan
          is_index_created
          index_updated_at
          available_avatar_count
          is_chief_available
          is_live_avatar_available
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          user_id: user_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((userCompaniesQueryResult: any) => {
        const companies: Company[] = get(
          userCompaniesQueryResult,
          "data.userCompanies",
          null
        );

        resolve(companies);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
