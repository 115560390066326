import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { eventEmitter } from "./EventEmiitter";

const LogoutListener = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    const handleLogout = () => {
      logout({
        openUrl: (url) => {
          window.location.href = process.env.REACT_APP_PUBLIC_URL
            ? process.env.REACT_APP_PUBLIC_URL
            : "";
        },
      });
    };

    eventEmitter.on("logout", handleLogout);

    return () => {
      eventEmitter.off("logout", handleLogout);
    };
  }, [logout]);

  return null;
};

export default LogoutListener;
