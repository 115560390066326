import React, { FC, useState, useEffect } from "react";

import { KTIcon } from "../../../helpers";
import stores from "../../../app/stores";
import {
  createCheckoutSession,
  getStripeProfileSession,
} from "../../../helpers/api";
import getDateRangeCompanyTokenUsagesGroups from "../../../helpers/api/getDateRangeCompanyTokenUsagesGroups";

import TokenPriceTable from "./TokenPriceTable";
import TokenUsageTable from "./TokenUsageTable";

interface CompanyTokenUsageSummary {
  model_id: number;
  total_token_count: number;
}

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

if (!stripeKey) {
  throw new Error("Stripe key is not defined");
}

const UpgradePlan: FC = () => {
  const [isFreeUser, setIsFreeUser] = useState(true);
  const [currentMonthTokenUsages, setCurrentMonthTokenUsages] = useState<
    CompanyTokenUsageSummary[]
  >([]);
  const [totalTokenUsages, setTotalTokenUsages] = useState<
    CompanyTokenUsageSummary[]
  >([]);

  const totalFreeTokenCount = process.env.REACT_APP_FREE_TOKEN_COUNT
    ? parseInt(process.env.REACT_APP_FREE_TOKEN_COUNT)
    : 25000;
  const usedTokenCount =
    totalFreeTokenCount - stores.companyStore.freeTokenCount;
  const progress = Math.round((100 * usedTokenCount) / totalFreeTokenCount);

  const color =
    progress < 50 ? "primary" : progress > 75 ? "danger" : "warning";

  useEffect(() => {
    const checkSubscriptionAndTokenUsage = async () => {
      if (stores.userStore.currentUser.subscription) {
        setIsFreeUser(false);

        // Fetch token usage data for Premium users
        const companyId = stores.companyStore.selectedUserCompany?.id;
        if (companyId) {
          const today = new Date();
          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          const startOfMonthFormatted = startOfMonth
            .toISOString()
            .split("T")[0];
          const endOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          const endOfMonthFormatted = endOfMonth.toISOString().split("T")[0];

          const currentMonthTokenUsageData =
            await getDateRangeCompanyTokenUsagesGroups(
              companyId,
              startOfMonthFormatted,
              endOfMonthFormatted
            );

          setCurrentMonthTokenUsages(currentMonthTokenUsageData);

          const totalTokenUsageData =
            await getDateRangeCompanyTokenUsagesGroups(
              companyId,
              "2023-01-01",
              endOfMonthFormatted
            );

          setTotalTokenUsages(totalTokenUsageData);
        }
      } else {
        setIsFreeUser(true);
      }
    };

    checkSubscriptionAndTokenUsage();
  }, []);

  const handleUpgradePlan = async () => {
    try {
      if (stores.userStore.currentUser.stripeProfile) {
        const priceIds = stores.companyModelStore.models.flatMap(
          (model) => model.stripe_price_id
        );

        const checkoutSession = await createCheckoutSession(
          stores.userStore.currentUser.stripeProfile.id,
          priceIds,
          `${process.env.REACT_APP_PUBLIC_URL}/success`
        );

        window.location.href = checkoutSession.url;
        sessionStorage.setItem("stripeSessionId", checkoutSession.id);
      }
    } catch (error) {
      console.error("Error during subscription update:", error);
    }
  };

  const handleCancelMyPlanClick = async () => {
    if (stores.userStore.currentUser.stripeProfile) {
      const updatePaymentMethodSession = await getStripeProfileSession(
        stores.userStore.currentUser.stripeProfile.id,
        //@ts-ignore
        process.env.REACT_APP_PUBLIC_URL
      );

      window.location.href = updatePaymentMethodSession.url;
    }
  };

  return (
    <div className="modal fade" id="kt_modal_upgrade_plan" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded shadow-sm">
          <div className="modal-header justify-content-end border-0 pb-0">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </button>
          </div>

          <div className="modal-body pt-0 pb-15 px-5 px-xxl-15">
            <div className="text-center mb-5">
              <h1 className="mb-3">My Plan</h1>
              <p className="text-muted fw-bold fs-5">
                You are currently using the{" "}
                <strong>{isFreeUser ? "Free" : "Premium"}</strong> plan.
              </p>
            </div>

            {/* Free users see token usage progress */}
            {isFreeUser ? (
              <div className="d-flex justify-content-center mb-5">
                <div className="card card-flush border-0 shadow-none w-100 max-w-600px">
                  <div className="card-body p-3">
                    <div className="d-flex align-items-center">
                      <KTIcon
                        iconName="duotune/abstract/abs021"
                        className={`svg-icon svg-icon-3x text-${color} me-4`}
                      />
                      <div className="d-flex flex-column flex-grow-1 m-2">
                        <div className="d-flex mb-2">
                          <span
                            style={{ flex: 1 }}
                            className={`text-${color} fw-bold fs-6 `}
                          >
                            Free Token Usage
                          </span>

                          <span className="fw-semibold fs-7 text-muted ms-4">{`${progress}%`}</span>
                        </div>

                        <div className="progress h-8px w-100 bg-light-dark">
                          <div
                            className={`progress-bar bg-${color}`}
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="col-lg-12 mt-4">
                  <TokenUsageTable
                    models={stores.companyModelStore.models}
                    currentMonthTokenUsageSummary={currentMonthTokenUsages}
                    totalTokenUsageSummary={totalTokenUsages}
                  />
                </div>
              </div>
            )}

            {/* Only show Upgrade plan and price table to free users */}
            {isFreeUser && (
              <div className="d-flex flex-column">
                {/* <div className="col-lg-6 offset-lg-3 mb-5">
                  <div className="tab-content rounded h-100 bg-light p-10">
                    <div className="pb-5">
                      <h2 className="fw-bolder text-dark text-center">
                        Upgrade to Premium Plan
                      </h2>
                      <div className="text-gray-400 fw-bold text-center">
                        {selectedPlan.description}
                      </div>
                    </div>

                    <div className="pt-1">
                      {selectedPlan.features.map((feature, i) => (
                        <div
                          key={`${i}-${feature.title}`}
                          className={`d-flex align-items-center ${
                            i !== selectedPlan.features.length - 1 ? "mb-7" : ""
                          }`}
                        >
                          <span
                            className={`fw-bold fs-5 ${
                              feature.supported
                                ? "text-gray-700"
                                : "text-gray-400"
                            } flex-grow-1`}
                          >
                            {feature.title}
                          </span>
                          <KTIcon
                            iconName={
                              feature.supported
                                ? "check-circle"
                                : "cross-circle"
                            }
                            className={`fs-1 ${
                              feature.supported ? "text-success" : ""
                            }`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-12 mt-4">
                  <TokenPriceTable models={stores.companyModelStore.models} />
                </div>
              </div>
            )}

            <div className="d-flex flex-column flex-center flex-row-fluid">
              <div className="d-flex flex-center">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                >
                  {isFreeUser ? "Cancel" : "Close"}
                </button>
                {isFreeUser ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpgradePlan}
                  >
                    Upgrade to Premium Plan
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline"
                    onClick={handleCancelMyPlanClick}
                  >
                    Cancel My Plan
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UpgradePlan };
