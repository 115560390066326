import React from "react";

const CreateCompanyRedirectPage: React.FC<{ onNext: () => void }> = ({
  onNext,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-px text-center pt-15 pb-15">
          <h2 className="fs-2x fw-bold mb-0">Welcome to the CompanyDNA</h2>
          <p className="text-gray-500 fs-4 fw-semibold py-7">
            In order to use CompanyDNA, you must create a company. <br />
            Start by creating your company profile!
          </p>
          <button onClick={onNext} className="btn btn-primary fs-6 px-8 py-4">
            Create Company
          </button>
        </div>
        <div className="text-center pb-15 px-5">
          <img
            src="/media/illustrations/sigma-1/2.png"
            alt=""
            className="mw-100 h-500px h-sm-500px"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCompanyRedirectPage;
