/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { KTIcon, useDebounce } from "../../../../../../helpers";
import stores from "../../../../../stores";

export const CompanyUsersListSearchComponent: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  // Effect for API call
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      stores.companyUserStore.filterAndSortCustomUsers(searchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="card-title">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative my-1">
        <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-6" />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-250px ps-14"
          placeholder="Search user"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};
