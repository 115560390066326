import { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

interface Props {
  onClick: () => void;
}

interface State {}

@observer
export default class AlertModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_connection_alert"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header pb-0 border-0">
                <h3 className="align-items-start flex-row">
                  <span className="fw-bold text-dark">Warning</span>
                </h3>
              </div>

              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  <span className="text-gray-700 mt-1 fw-semibold fs-6">
                    To allow your application data to be saved and later
                    structured by CompanyDNA for indexing, please connect a
                    storage solution.
                  </span>
                </div>
              </div>

              <div className="d-flex flex-center mb-8">
                <Link
                  to={"/storages"}
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={this.props.onClick}
                >
                  Connect Storage
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
