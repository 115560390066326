import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { PageLink, PageTitle } from "../../../layout/core";

import ManageConnectionDataPage from "./ManageConnectionDataPage";

function withRouter(Component: any) {
  function ComponentWithRouter(props: any) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}

const appsBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/manage-connection-data ",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class ManageConnectionDataPageWrapper extends React.Component {
  render() {
    //@ts-ignore
    const appId = this.props.params.id;

    return (
      <>
        <PageTitle breadcrumbs={appsBreadCrumbs}>
          Manage Connection Data
        </PageTitle>

        <ManageConnectionDataPage appId={appId} />
      </>
    );
  }
}

export default withRouter(ManageConnectionDataPageWrapper);
