import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import TopBarProgress from "react-topbar-progress-indicator";

import { MasterLayout } from "../../layout/MasterLayout";
// import { DashboardWrapper } from "../pages/Dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../assets/ts/_utils";
import { WithChildren } from "../../helpers";
import { CompanyUsersWrapper } from "../pages/CompanyUsers/CompanyUsersWrapper";
import { ConnectionsWrapper } from "../pages/CompanyConnections/ConnectionsWrapper";
import { CompanyModelsWrapper } from "../pages/CompanyModels/CompanyModelsWrapper";
import { CompanyStoragesWrapper } from "../pages/CompanyStorages/CompanyStoragesWrapper";
// import AppsWrapper from "../pages/AppsPage/AppsWrapper";
import { AvatarWrapper } from "../pages/CompanyAvatars/AvatarsWrapper";
import { ChatWrapper } from "../pages/ChatPage/ChatWrapper";
import stores from "../stores";
// import ManageConnectionDataPageWrapper from "../pages/ManageConnectionDataPage/ManageConnectionDataPageWrapper";

const PrivateRoutes: FC = observer(() => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const AccountPage = lazy(() => import("../pages/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));

  // Check if running in Electron
  const isElectron = window.electronAPIs?.isElectron ?? false;

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="chat" element={<ChatWrapper />} />

        {!isElectron && stores.userStore.isCurrentUserAdmin ? (
          <>
            {/* Redirect to Dashboard after success login/registration */}
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />
            {/* <Route path="wizard/*" element={<Navigate to="/dashboard" />} /> */}
            {/* Pages */}
            {/* <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="apps" element={<AppsWrapper />} />*/}
            <Route path="company-users" element={<CompanyUsersWrapper />} />
            <Route path="connections" element={<ConnectionsWrapper />} />
            {/* <Route
          path="/manage-connection-data/:id"
          element={<ManageConnectionDataPageWrapper />}
        /> */}
            <Route path="models" element={<CompanyModelsWrapper />} />
            <Route path="storages" element={<CompanyStoragesWrapper />} />
            <Route path="avatars" element={<AvatarWrapper />} />
            {/* Lazy Modules */}

            <Route
              path="crafted/pages/profile/*"
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route
              path="crafted/pages/wizards/*"
              element={
                <SuspensedView>
                  <AvatarWrapper />
                </SuspensedView>
              }
            />
            <Route
              path="crafted/widgets/*"
              element={
                <SuspensedView>
                  <WidgetsPage />
                </SuspensedView>
              }
            />
            <Route
              path="crafted/account/*"
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/chat" />} />
        )}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
});

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
