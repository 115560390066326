/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserActionLog } from "../../app/models/UserActionLog";

export default async function updateUserActionLog(
  userActionLog: UserActionLog
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateUserActionLog(
        id: ${userActionLog.id}
        user_id: ${userActionLog.user_id}
        action: "${userActionLog.action}"
        action_result: "${userActionLog.action_result}"
        action_code: ${userActionLog.action_code}
        action_result_code: ${userActionLog.action_result_code}
        action_status: "${userActionLog.action_status}"
        modified_at: ${userActionLog.modified_at}
      ) 
    }
  `;

  const updateUserActionLogMutationResult = await client.mutate({ mutation });

  const result = get(
    updateUserActionLogMutationResult,
    "data.updateUserActionLog",
    null
  );

  return result !== null && result[0] > 0;
}
