/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Model } from "../../app/models/Model";

export default async function getModels(): Promise<Model[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query ModelsQuery {
        models {
          id
          stripe_price_id
          name
          model_name
          description
          logo
          price_token_count
          price_input
          price_output
          price
          token_count
          published
        }
      }
    `;

    client
      .query({
        query: query,
        fetchPolicy: "no-cache",
      })
      .then((ModelsQueryResult: any) => {
        const models: Model[] = get(ModelsQueryResult, "data.models", null);

        resolve(models);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
