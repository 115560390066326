import { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import getSources from "../../../helpers/api/getSources";
import { toAbsoluteUrl } from "../../../helpers";
import { ListItems } from "../../models/ListItems";
import { App } from "../../models/App";
import stores from "../../stores";
import ListItem from "./ListItem";

interface Props {
  appId: number;
}

interface State {
  app: App | null;
  isConnectAppModalOpened: boolean;
  sources: any;
  error: string | null;
  folders: ListItems[];
  openFolderIds: Map<string, boolean>;
  shouldRedirect: boolean;
  source_ids: string[];
  fileCount: number;
}

export default class ManageConnectionDataPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      app: null,
      isConnectAppModalOpened: false,
      sources: null,
      error: null,
      folders: [],
      openFolderIds: new Map<string, boolean>(),
      shouldRedirect: false,
      source_ids: [],
      fileCount: 0,
    };
  }

  componentDidMount() {
    this.initializePageData();
  }

  private initializePageData = async () => {
    const { appId } = this.props;
    const app = stores.companyAppStore.apps.find(
      (app) => app.id.toString() === appId.toString()
    );

    if (app) this.setState({ app });

    const companyApp = stores.companyAppStore.companyApps.find(
      (app) => app.app_id.toString() === appId.toString()
    );

    if (companyApp) {
      if (companyApp.source_ids !== undefined) {
        this.setState({ source_ids: companyApp.source_ids });
      }

      try {
        const result = await getSources(companyApp.id);
        if (result.success) {
          this.setState({
            folders: [result.sources],
          });
          this.updateFileCount(companyApp.source_ids);
        } else {
          console.error("Failed to retrieve data:", result.message);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error:", error.message);
        } else {
          console.error("Unexpected error:", error);
        }
      }
    } else {
      console.error("No matching company app found");
    }
  };

  private updateFileCount = (source_ids: string[]) => {
    this.setState({ fileCount: source_ids.length });
  };

  private handleSelectedFoldersChange = (sources_ids: string[]) => {
    this.setState({ source_ids: sources_ids }, () => {
      this.updateFileCount(sources_ids);
    });
  };

  private toggleFolder = (folderId: string) => {
    this.setState((prevState) => {
      const newOpenFolderIds = new Map(prevState.openFolderIds);
      newOpenFolderIds.set(folderId, !newOpenFolderIds.get(folderId));
      return { openFolderIds: newOpenFolderIds };
    });
  };

  private handleSyncApp = async () => {
    const { appId } = this.props;
    const app = stores.companyAppStore.apps.find(
      (app) => app.id.toString() === appId.toString()
    );
    const appIndex = stores.companyAppStore.companyApps.findIndex(
      (companyApp) => companyApp.app.id === app?.id
    );
    ReactGA.event({
      category: "manage_connection_data_page",
      action: "sycn_connection_data",
      label: "sycn_connection_data_button",
    });
    if (appIndex !== -1 && stores.companyStore.selectedUserCompany) {
      await stores.companyAppStore.syncCompanyAppData(
        stores.companyAppStore.companyApps[appIndex]
      );
      this.setState({ shouldRedirect: true });
    }
  };

  private handleCloseButton = async () => {
    const { app } = this.state;
    const companyApp = stores.companyAppStore.companyApps.find(
      (companyApp) => companyApp.app_id.toString() === app?.id.toString()
    );
    const companyAppIndex = stores.companyAppStore.companyApps.findIndex(
      (item) => item.id === companyApp?.id
    );

    if (companyAppIndex !== -1 && companyApp) {
      try {
        if (
          companyApp.source_ids.length === 0 ||
          companyApp.last_sync_at === 0
        ) {
          await stores.companyAppStore.deleteCompanyApp(
            stores.companyAppStore.companyApps[companyAppIndex]
          );
        }
        this.setState({ shouldRedirect: true });
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error:", error.message);
        } else {
          console.error("Unexpected error:", error);
        }
      }
    }
  };

  private renderHeader = () => (
    <div className="d-flex justify-content-between align-items-center p-2">
      <span className="fw-bold fs-3">Add / Remove data</span>
      <div
        className="btn justify-content-center align-items-center"
        onClick={this.handleCloseButton}
      >
        <Link
          to={`/connections`}
          onClick={() =>
            ReactGA.event({
              category: "manage_connection_data_page",
              action: "close_manage_connection_data_page",
              label: "close_manage_connection_data_page_button",
            })
          }
        >
          <i className="fa fa-times fs-2"></i>
        </Link>
      </div>
    </div>
  );

  private renderFilteredFolders = (
    folders: ListItems[],
    filteredFileIds: string[]
  ): JSX.Element => (
    <ul className="list-unstyled">
      {folders.map((folder) => {
        const folderAndChildrenIds = this.getFolderAndChildrenIds(folder);
        if (filteredFileIds.some((id) => folderAndChildrenIds.includes(id))) {
          return (
            <li key={folder.id}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {folder.children && folder.children.length > 0 && (
                    <span onClick={() => this.toggleFolder(folder.id)}>
                      <i
                        className={`fa ${
                          this.state.openFolderIds.get(folder.id)
                            ? "fa-chevron-down"
                            : "fa-chevron-right"
                        } fs-2 ms-5`}
                      ></i>
                    </span>
                  )}
                  <i
                    className={
                      folder.mimeType.includes("folder")
                        ? this.state.openFolderIds.get(folder.id)
                          ? "far fa-folder-open fs-2 ms-5"
                          : "far fa-folder fs-2 ms-5"
                        : "fa-regular fa-file fs-2 ms-5"
                    }
                  ></i>
                  <span className="ms-3 fw-bold">{folder.name}</span>
                </div>
              </div>
              {this.state.openFolderIds.get(folder.id) && folder.children && (
                <div className="ms-10 mt-2">
                  {this.renderFilteredFolders(folder.children, filteredFileIds)}
                </div>
              )}
            </li>
          );
        }
        return null;
      })}
    </ul>
  );

  private getFolderAndChildrenIds = (folder: ListItems): string[] => {
    let ids: string[] = [folder.id];
    if (folder.children) {
      folder.children.forEach((child) => {
        ids = ids.concat(this.getFolderAndChildrenIds(child));
      });
    }
    return ids;
  };

  private renderAppDetails = () => {
    const { appId } = this.props;
    const { app, folders, source_ids, fileCount } = this.state;

    return (
      <div className="d-flex justify-content-center">
        <div
          className="card mx-5 mb-xl-10"
          style={{
            width: "55%",
            display: "flex",
            flexDirection: "column",
            minHeight: "80vh",
          }}
        >
          <div className="d-flex align-items-center mb-4">
            <div style={{ width: "40px", height: "40px", marginRight: "10px" }}>
              <img
                src={toAbsoluteUrl(app?.logo || "default-logo-path")}
                alt="Logo"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div>
              <h1 className="mb-0">{`Manage CompanyDNA connection to ${app?.name}`}</h1>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-4">
            <p className="mb-0">
              Selected resources will be accessible to all members of the
              workspace. Changes may impact existing assistants.
            </p>
          </div>

          <div className="separator mb-4"></div>

          <h2 className="fw-bold m-0">Selected data</h2>
          <div style={{ marginBottom: "10px" }}>
            <p>{`Selected Files: ${fileCount}/1000`}</p>
          </div>

          <div style={{ flex: 0.5 }}>
            <ListItem
              onCancel={() => this.setState({ isConnectAppModalOpened: false })}
              app={appId}
              onSelectedFoldersChange={this.handleSelectedFoldersChange}
              folders={folders}
            />
          </div>

          <div className="d-flex justify-content-end mt-auto">
            <button
              className="btn btn-primary mb-4"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleSyncApp}
              disabled={source_ids.length === 0}
            >
              <i className="fa fa-rotate"></i> Sync Data
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { shouldRedirect } = this.state;

    if (shouldRedirect) {
      return <Navigate to={`/`} />;
    }

    return (
      <div>
        {this.renderHeader()}
        <div className="separator separator-dashed mb-15"></div>
        {this.renderAppDetails()}
      </div>
    );
  }
}
