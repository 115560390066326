import { Column } from "react-table";

import { CustomUser } from "../../../../../models/CustomUser";

import { CompanyUserNameInfoCell } from "./CompanyUserNameInfoCell";
import { CompanyUserMailInfoCell } from "./CompanyUserMailInfoCell";
import { CompanyUserRoleCell } from "./CompanyUserRoleCell";
import { CompanyUserCustomHeader } from "./CompanyUserCustomHeader";
import { CompanyUserDepartmentsCell } from "./CompanyUserDepartmentsCell";
import { CompanyUserStatusCell } from "./CompanyUserStatusCell";
import { CompanyUserActionsCell } from "./CompanyUserActionsCell";

// import { CompanyUserActionsCell } from "./CompanyUserActionsCell";
// import { CompanyUserSelectionCell } from "./CompanyUserSelectionCell";
// import { CompanyUserSelectionHeader } from "./CompanyUserSelectionHeader";

const companyUsersColumns: ReadonlyArray<Column<CustomUser>> = [
  // {
  //   Header: (props) => <CompanyUserSelectionHeader tableProps={props} />,
  //   id: "selection",
  //   Cell: ({ ...props }) => (
  //     <CompanyUserSelectionCell companyUser={props.data[props.row.index]} />
  //   ),
  // },
  {
    Header: (props) => (
      <CompanyUserCustomHeader
        tableProps={props}
        title="Name"
        className="min-w-125px pe-3"
      />
    ),
    id: "name",
    Cell: ({ ...props }) => (
      <CompanyUserNameInfoCell customUser={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CompanyUserCustomHeader
        tableProps={props}
        title="Mail"
        className="min-w-125px pe-3"
      />
    ),
    id: "mail",
    Cell: ({ ...props }) => (
      <CompanyUserMailInfoCell customUser={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CompanyUserCustomHeader
        tableProps={props}
        title="Status"
        className="text-center min-w-125px pe-3"
      />
    ),
    id: "status",
    Cell: ({ ...props }) => (
      <CompanyUserStatusCell status={props.data[props.row.index].status} />
    ),
  },
  {
    Header: (props) => (
      <CompanyUserCustomHeader
        tableProps={props}
        title="Role"
        className="text-center min-w-125px pe-3"
      />
    ),
    id: "role",
    Cell: ({ ...props }) => (
      <CompanyUserRoleCell customUser={props.data[props.row.index]} />
    ),
  },

  {
    Header: (props) => (
      <CompanyUserCustomHeader
        tableProps={props}
        title="User Departments"
        className="text-center min-w-125px pe-3"
      />
    ),
    id: "user_departments",
    Cell: ({ ...props }) => (
      <CompanyUserDepartmentsCell customUser={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <CompanyUserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px pe-3"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <CompanyUserActionsCell customUser={props.data[props.row.index]} />
    ),
  },
  // {
  //   Header: (props) => (
  //     <CompanyUserCustomHeader
  //       tableProps={props}
  //       title="Actions"
  //       className="text-end min-w-100px pe-3"
  //     />
  //   ),
  //   id: "actions",
  //   Cell: ({ ...props }) => (
  //     <CompanyUserActionsCell companyUser={props.data[props.row.index]} />
  //   ),
  // },
];

export { companyUsersColumns };
