import React, { ReactNode } from "react";

interface WizardLayoutProps {
  children: ReactNode;
}

export const WizardLayout: React.FC<WizardLayoutProps> = ({ children }) => (
  <div className="d-flex flex-column h-100">{children}</div>
);

interface WizardHeaderProps {
  children: ReactNode;
}

export const WizardHeader: React.FC<WizardHeaderProps> = ({ children }) => (
  <div className="stepper stepper-links stepper-horizontal bg-white pb-10 pt-20">
    <div className="stepper-nav">{children}</div>
  </div>
);

interface WizardContentProps {
  children: ReactNode;
}

export const WizardContent: React.FC<WizardContentProps> = ({ children }) => (
  <div className="flex-grow-1 overflow-auto px-5">{children}</div>
);

interface WizardFooterProps {
  children: ReactNode;
}

export const WizardFooter: React.FC<WizardFooterProps> = ({ children }) => (
  <div className="d-flex justify-content-between mt-5">{children}</div>
);
