/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { DocumentMessage } from "../../app/models/DocumentMessage";

export default async function updateDocumentMessage(
  documentMessage: DocumentMessage
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateDocumentMessage(
        id: ${documentMessage.id}
        message: "${documentMessage.message}"
        message_reply: "${documentMessage.message_reply}",
        source_documents: "${documentMessage.source_documents}",
        is_liked: ${
          documentMessage.is_liked === undefined ||
          documentMessage.is_liked === null
            ? null
            : Number(documentMessage.is_liked)
        } 

      ) 
    }
  `;

  const updateDocumentMessageMutationResult = await client.mutate({ mutation });

  const result = get(
    updateDocumentMessageMutationResult,
    "data.updateDocumentMessage",
    null
  );

  return result !== null && result[0] > 0;
}
