import { observer } from "mobx-react";

import { CompanyUserEditModalForm } from "./CompanyUserEditModalForm";
import stores from "../../../../stores";

export const CompanyUserEditModalFormWrapper: React.FC = observer(() => {
  return (
    <CompanyUserEditModalForm
      companyUser={stores.companyUserStore.selectedCompanyUser}
    />
  );
});
