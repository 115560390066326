import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import i18n from "../../../../../../i18n";
import { MenuComponent } from "../../../../../../assets/ts/components";
import { KTIcon } from "../../../../../../helpers";
import stores from "../../../../../stores";

export const CompanyUsersListFilter: React.FC = observer(() => {
  const [roleId, setRoleId] = useState<number | undefined>();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const resetData = () => {
    setRoleId(undefined);
    stores.companyUserStore.filterAndSortCustomUsers(undefined, undefined);
  };

  const filterData = () => {
    if (roleId !== undefined) {
      stores.companyUserStore.filterAndSortCustomUsers(undefined, roleId);
    }
  };

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={stores.companyUserStore.isLoading}
        type="button"
        className="btn btn-light-primary me-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={() =>
          ReactGA.event({
            category: "company_users",
            action: "filter_users",
            label: "filter_user_button",
          })
        }
      >
        <KTIcon iconName="filter" className="fs-2" />
        {i18n.CompanyUsersListFilter.buttons.filter}
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
        data-kt-menu="true"
      >
        {/* begin::Header */}
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">
            {i18n.CompanyUsersListFilter.filterOptionsTitle}
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className="separator border-gray-200"></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className="px-7 py-5" data-kt-user-table-filter="form">
          {/* begin::Input group */}
          <div className="mb-10">
            <label className="form-label fs-6 fw-bold">
              {i18n.CompanyUsersListFilter.label.role}
            </label>
            <select
              className="form-select form-select-solid fw-bolder"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              data-kt-user-table-filter="role"
              data-hide-search="true"
              onChange={(e) => setRoleId(Number(e.target.value))}
              value={roleId ? roleId.toString() : ""}
            >
              <option value=""></option>
              <option value="1">Admin</option>
              <option value="2">Member</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className="d-flex justify-content-end">
            <button
              type="button"
              disabled={stores.companyUserStore.isLoading}
              onClick={resetData}
              className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="reset"
            >
              {i18n.CompanyUsersListFilter.buttons.reset}
            </button>

            <button
              disabled={stores.companyUserStore.isLoading}
              type="button"
              onClick={filterData}
              className="btn btn-primary fw-bold px-6"
              data-kt-menu-dismiss="true"
              data-kt-user-table-filter="filter"
            >
              {i18n.CompanyUsersListFilter.buttons.apply}
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  );
});
