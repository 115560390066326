/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { CompanyAvatar } from "../../../models/CompanyAvatar";
import stores from "../../../stores";
import { UserDocument } from "../../../models/UserDocument";
import { toAbsoluteUrl } from "../../../../helpers";
import { CompanyAvatarStatus } from "../../../../helpers/Enums";

interface Props {
  userDocument?: UserDocument;
  onCompanyAvatarSelect: (companyAvatar: CompanyAvatar | undefined) => void;
}

@observer
export default class AvatarSelectionTableView extends React.Component<Props> {
  state = {
    isElectron: false,
  };

  constructor(props: Props) {
    super(props);

    // Check if running in Electron
    this.state.isElectron = window.electronAPIs?.isElectron ?? false;
  }

  private renderCompanyAvatar = (
    companyAvatar: CompanyAvatar,
    index: number
  ) => {
    return (
      <tr key={index} className="odd">
        <td>
          <div
            onClick={() => this.props.onCompanyAvatarSelect(companyAvatar)}
            className="d-flex align-items-center cursor-pointer"
          >
            <div className="me-5 position-relative">
              <div className="symbol symbol-50px me-1">
                <span className="symbol-label">
                  <img
                    src={toAbsoluteUrl(companyAvatar.logo_url)}
                    className="h-50 align-self-center"
                    alt=""
                  />
                </span>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center">
              <a className="fs-6 text-gray-800 text-hover-primary">
                {companyAvatar.name}
              </a>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { isElectron } = this.state;

    // Filter avatars to include only those with the Connected status
    const connectedAvatars = stores.companyAvatarStore.companyAvatars.filter(
      (avatar) => avatar.status === CompanyAvatarStatus.Connected
    );

    return (
      <div className="card">
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table
              id="kt_profile_overview_table"
              className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer"
            >
              <tbody className="fs-6">
                {connectedAvatars.length > 0 ? (
                  connectedAvatars.map(this.renderCompanyAvatar)
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-8">
                        Avatar not found.
                      </div>

                      {!isElectron && ( // Hide the link if running in Electron
                        <Link
                          to="/avatars"
                          className="btn btn-primary px-6 py-3 mt-3"
                        >
                          Connect Avatar
                        </Link>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
