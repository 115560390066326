import React, { Component } from "react";
import { observer } from "mobx-react";
import Nango from "@nangohq/frontend";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import Functions from "../../../helpers/Functions";
import { App } from "../../models/App";
import { CompanyApp } from "../../models/CompanyApp";
import { ConnectionParam } from "../../models/ConnectionParam";
import { ConnectionCredential } from "../../models/ConnectionCredential";
import { toAbsoluteUrl } from "../../../helpers";
import stores from "../../stores";
import AppParamView from "./AppParamView";
import { AuthTypes } from "../../../helpers/Enums";

interface Props {
  app: App;
  onCancel: () => void;
  onConnect: (credential?: ConnectionCredential) => void;
  onUpdate: (companyApp: CompanyApp, credential?: ConnectionCredential) => void;
  onDisconnect: (companyApp: CompanyApp) => void;
}

interface State {
  companyApp?: CompanyApp;
  params: ConnectionParam[];
  credential?: ConnectionCredential;
  activationMessage: string;
  hasError: boolean;
  isLoading: boolean;
  shouldRedirect: boolean;
}

@observer
export default class ConnectAppModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      params: this.props.app.params,
      activationMessage: "",
      hasError: false,
      isLoading: false,
      shouldRedirect: false,
    };
  }

  componentWillMount() {
    const { app } = this.props;

    const companyApp = stores.companyAppStore.companyApps.find(
      (companyApp) => companyApp.app_id.toString() === app.id.toString()
    );

    if (companyApp) this.setState({ companyApp });
  }

  private handleTestClick = async (credential?: ConnectionCredential) => {
    const { app } = this.props;

    ReactGA.event({
      category: "connections_page",
      action: "test_app_button",
      label: "test_app_button",
    });

    if (stores.companyStore.selectedUserCompany) {
      this.setState({ isLoading: true });

      const nango = new Nango({
        //@ts-ignore
        publicKey: process.env.REACT_APP_NANGO_PUBLIC_KEY,
      });

      const connectionId = `${stores.companyStore.selectedUserCompany.id}-${app.id}`;
      const credentials =
        app.auth_type && app.auth_type === AuthTypes.ApiKey && credential
          ? {
              apiKey: credential.params.find(
                (param) => param.key === AuthTypes.ApiKey
              )?.info,
            }
          : undefined;

      nango
        .auth(app.integration_id, connectionId, {
          params: credential
            ? Functions.transformArrayToObject(credential.params)
            : {},
          credentials: credentials ? credentials : {},
        })
        .then(
          async (result: {
            providerConfigKey: string;
            connectionId: string;
          }) => {
            const updatedCredential: ConnectionCredential = this.state
              .credential
              ? this.state.credential
              : {
                  params: [],
                  connectionId: "",
                };
            updatedCredential.connectionId = connectionId;

            this.setState({
              credential: updatedCredential,
              activationMessage: "Connection success.",
              hasError: false,
              isLoading: false,
            });
          }
        )
        .catch((err: { message: string; type: string }) => {
          this.setState({
            activationMessage: `Connection error: ${err.message}`,
            hasError: true,
            isLoading: false,
          });
        });
    }
  };

  private handleConnectLinkClick = async () => {
    const { credential } = this.state;
    const { app } = this.props;

    ReactGA.event({
      category: "connections_page",
      action: "connect_app_button",
      label: "connect_app_button",
    });

    this.setState({ isLoading: true });
    try {
      await stores.companyAppStore.createCompanyApp(
        app,
        credential?.connectionId
      );
      this.setState({ shouldRedirect: true });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  private handleUpdateLinkClick = async () => {
    this.setState({ isLoading: true, shouldRedirect: true });
  };

  private handleParamChange = (
    credential: ConnectionCredential | undefined,
    activationMessage: string,
    hasError: boolean
  ) => {
    this.setState({
      credential,
      activationMessage,
      hasError,
    });
  };

  render() {
    const { app } = this.props;
    const {
      companyApp,
      params,
      credential,
      isLoading,
      activationMessage,
      hasError,
      shouldRedirect,
    } = this.state;
    if (shouldRedirect) {
      return <Navigate to={`/manage-connection-data/${app.id}`} />;
    }

    return (
      <>
        <div
          className="modal fade show d-block"
          id="kt_modal_add_company_users_apps"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header pb-0 border-0">
                <h3 className="align-items-start flex-row">
                  <img
                    src={toAbsoluteUrl(app.logo)}
                    style={{ width: 40 }}
                    className="align-self-center me-3"
                    alt=""
                  />

                  <span className="fw-bold text-dark">{app.name}</span>
                </h3>
              </div>

              <div className="modal-body py-lg-10 px-lg-10">
                <div className="current" data-kt-stepper-element="content">
                  {app.important ? (
                    <>
                      <div className="fw-bolder text-dark fs-4 mb-1">
                        Important
                      </div>

                      <span className="text-gray-700 mt-1 fw-semibold fs-6">
                        {app.important}
                      </span>
                    </>
                  ) : null}

                  {app.limitations ? (
                    <>
                      <div className="fw-bolder text-dark fs-4 mb-1 mt-3">
                        Limitations
                      </div>

                      <span className="text-gray-700 mt-1 fw-semibold fs-6">
                        {app.limitations}
                      </span>
                    </>
                  ) : null}

                  <div className="w-140">
                    <AppParamView
                      app={app}
                      activationMessage={activationMessage}
                      credential={credential}
                      hasError={hasError}
                      params={params}
                      handleParamChange={this.handleParamChange}
                    />

                    {activationMessage ? (
                      <div className="fv-plugins-message-container mt-4">
                        <span
                          className={`fw-bolder fs-5 text-${
                            hasError ? "danger" : "success"
                          }`}
                        >
                          {activationMessage}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex flex-center pt-10">
                  {companyApp ? (
                    <button
                      type="reset"
                      className="btn btn-lg btn-color-danger me-4 fw-bolder px-6 py-3"
                      onClick={() => this.props.onDisconnect(companyApp)}
                    >
                      Disconnect
                    </button>
                  ) : null}

                  <div style={{ flex: 1 }}></div>

                  <div>
                    <button
                      type="reset"
                      className="btn btn-lg btn-color-gray-600 me-4 fw-bolder px-6 py-3"
                      onClick={this.props.onCancel}
                    >
                      Cancel
                    </button>

                    {!companyApp ? (
                      this.props.app.is_source_available === 1 ? (
                        activationMessage && !hasError ? (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={isLoading}
                            onClick={() => {
                              this.handleConnectLinkClick();
                            }}
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : (
                              "Activate"
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled={isLoading}
                            onClick={() => {
                              this.handleTestClick(credential);
                            }}
                          >
                            {isLoading ? (
                              <span className="spinner-border spinner-border-sm align-middle"></span>
                            ) : (
                              "Test"
                            )}
                          </button>
                        )
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled={isLoading}
                          onClick={() => {
                            if (activationMessage && !hasError)
                              this.props.onConnect(credential);
                            else this.handleTestClick(credential);
                          }}
                        >
                          {isLoading ? (
                            <span className="spinner-border spinner-border-sm align-middle"></span>
                          ) : activationMessage && !hasError ? (
                            "Activate"
                          ) : (
                            "Test"
                          )}
                        </button>
                      )
                    ) : this.props.app.is_source_available === 1 ? (
                      activationMessage && !hasError ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled={isLoading}
                          onClick={() => {
                            this.handleUpdateLinkClick();
                          }}
                        >
                          {isLoading ? (
                            <span className="spinner-border spinner-border-sm align-middle"></span>
                          ) : (
                            "Update"
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled={isLoading}
                          onClick={() => {
                            this.handleTestClick(credential);
                          }}
                        >
                          {isLoading ? (
                            <span className="spinner-border spinner-border-sm align-middle"></span>
                          ) : (
                            "Test"
                          )}
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        onClick={() => {
                          if (activationMessage && !hasError)
                            this.props.onUpdate(companyApp, credential);
                          else this.handleTestClick(credential);
                        }}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm align-middle"></span>
                        ) : activationMessage && !hasError ? (
                          "Update"
                        ) : (
                          "Test"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
