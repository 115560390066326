// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyStorage } from "../../app/models/CompanyStorage";

export default async function createCompanyStorage(
  storage_id: number,
  company_id: number,
  credentials: string,
  status: string,
  modified_at: number,
  is_default: boolean
): Promise<CompanyStorage> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createCompanyStorage(
        storage_id: ${storage_id}
        company_id: ${company_id}
        credentials: "${credentials}"
        status: "${status}"
        modified_at: ${modified_at}
        is_default: ${Number(is_default)}
      ) {
        id
        storage_id
        company_id
        credentials
        status
        modified_at
        is_default
        storage {
          id
          name
          logo
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createCompanyStorageMutationResult: any) => {
        const companyStorage: CompanyStorage = get(
          createCompanyStorageMutationResult,
          "data.createCompanyStorage",
          null
        );

        if (companyStorage) {
          resolve(companyStorage);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
