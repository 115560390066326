/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { User } from "../../app/models/User";

export default async function getAuth0User(
  auth0_id: string
): Promise<User | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query UserQuery($auth0_id: String!) {
        auth0User(auth0_id: $auth0_id) {
          id
          auth0_id
          picture
          name
          email
          email_verified
          gender
          locale
          phone_number
          phone_number_verified
          settings
          is_onboarded
          created_at
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          auth0_id: auth0_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((auth0UserQueryResult: any) => {
        const user: User = get(auth0UserQueryResult, "data.auth0User", null);

        if (user) {
          if (user.settings)
            //@ts-ignore
            user.settings = JSON.parse(decodeURIComponent(user.settings));

          resolve(user);
        } else {
          resolve(undefined);
        }
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
