import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

import { PageLink, PageTitle } from "../../../layout/core";

import CompanyUsersPage from "./CompanyUsersPage";

const companyUsersBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/company-users",
    isSeparator: false,
    isActive: false,
  },
];

class CompanyUsersWrapper extends React.Component<WrappedComponentProps> {
  render() {
    const { intl } = this.props;
    return (
      <>
        <PageTitle breadcrumbs={companyUsersBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.COMPANY.USERS" })}
        </PageTitle>

        <CompanyUsersPage />
      </>
    );
  }
}

const CompanyUsersWrapperWithIntl = injectIntl(CompanyUsersWrapper);

export { CompanyUsersWrapperWithIntl as CompanyUsersWrapper };
