// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { DocumentMessage } from "../../app/models/DocumentMessage";

export default async function createDocumentMessage(
  user_document_id: number,
  company_app_id: number | undefined,
  company_avatar_id: number | undefined,
  created_at: number,
  message: string,
  message_reply: string
): Promise<DocumentMessage> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      createDocumentMessage(
        user_document_id: ${user_document_id}
        company_app_id: ${company_app_id ? company_app_id : null}
        company_avatar_id: ${company_avatar_id ? company_avatar_id : null}
        message: "${message}"
        message_reply: "${message_reply}"
        created_at: ${created_at}
        is_liked: null
      ) {
        id
        user_document_id
        company_app_id
        company_avatar_id
        message
        message_reply
        created_at
        is_liked
        user_document {
          id
          name
          created_at
        }
        company_app {
          id
          app_id
          company_id
        }
        company_avatar {
            id
            company_id
            name
            logo_url
          }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((createDocumentMessageMutationResult: any) => {
        const documentMessage: DocumentMessage = get(
          createDocumentMessageMutationResult,
          "data.createDocumentMessage",
          null
        );

        if (documentMessage) {
          resolve(documentMessage);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
