/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { CustomUser } from "../../../../../models/CustomUser";

type Props = {
  customUser: CustomUser;
};

export class CompanyUserMailInfoCell extends React.Component<Props> {
  render() {
    const { customUser } = this.props;

    return (
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <span>{customUser.email}</span>
        </div>
      </div>
    );
  }
}
