/* @flow */

import { CheckCredentialsResult } from "../../app/models/CheckCredentialsResult";
import { Model } from "../../app/models/Model";

export default async function checkModelApiKey(
  model: Model,
  apiKey: string
): Promise<CheckCredentialsResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/checkModelApiKey`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        modelId: model.id,
        apiKey: apiKey,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: CheckCredentialsResult = data;

          resolve(result);
        } else {
          reject();
        }
      });
  });
}
