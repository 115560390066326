import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";

import { MenuComponent } from "../../../../assets/ts/components";
import { toAbsoluteUrl } from "../../../../helpers";
import stores from "../../../stores";
import { CompanyModel } from "../../../models/CompanyModel";
import { CompanyAvatar } from "../../../models/CompanyAvatar";
import { UserDocument } from "../../../models/UserDocument";
import { FeatureType } from "../../../../helpers/Enums";

import ModelSelectionTableView from "./ModelSelectionTableView";
import AvatarSelectionTableView from "./AvatarSelectionTableView";

export const ModelAvatarSelectionModal: React.FC<{
  userDocument?: UserDocument;
  selectedCompanyModel?: CompanyModel;
  selectedCompanyAvatar?: CompanyAvatar;
  onCompanyModelSelect: (companyModel?: CompanyModel) => void;
  onCompanyAvatarSelect: (companyAvatar?: CompanyAvatar) => void;
}> = observer(
  ({
    userDocument,
    selectedCompanyModel,
    selectedCompanyAvatar,
    onCompanyModelSelect,
    onCompanyAvatarSelect,
  }) => {
    const [activeTab, setActiveTab] = useState("Models");
    const [canUseTheChief, setCanUseTheChief] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [hasAvatars, setHasAvatars] = useState(false);

    useEffect(() => {
      MenuComponent.reinitialization();
    }, []);

    useEffect(() => {
      const checkFeatureAccess = async () => {
        const canUse = await stores.userStore.checkSubscribedFeatureType(
          FeatureType.TheChief
        );
        setCanUseTheChief(canUse);
      };

      const checkHasAvatars = () => {
        const avatarsAvailable =
          stores.companyAvatarStore.companyAvatars.length > 0;
        setHasAvatars(avatarsAvailable);
      };

      checkFeatureAccess();
      checkHasAvatars();
    }, []);

    useEffect(() => {}, [selectedCompanyModel, selectedCompanyAvatar]);

    const handleTabSwitch = (tabName: string) => {
      setActiveTab(tabName);
    };

    let name = "";
    let logo = "";

    if (selectedCompanyAvatar) {
      name = selectedCompanyAvatar.name;
      logo = selectedCompanyAvatar.logo_url;
    } else if (selectedCompanyModel) {
      name = selectedCompanyModel.model.name;
      logo = selectedCompanyModel.model.logo;
    } else if (stores.userStore.isCurrentUserAdmin && canUseTheChief) {
      name = "The Chief";
      logo =
        "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/93bbf41d-9c33-44c7-b6eb-38fe5b9a1300/mid";
    } else {
      name = "Please select";
      logo = "";
    }

    return (
      <div tabIndex={-1}>
        <button
          ref={buttonRef}
          disabled={
            stores.companyAppStore.isLoading ||
            stores.companyAvatarStore.isAvatarLoading
          }
          type="button"
          className="btn btn-outline me-n8"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <img
            src={toAbsoluteUrl(logo)}
            className="align-self-center me-2"
            style={{ width: 30, borderRadius: 5 }}
            alt=""
          />

          {name}
        </button>

        <div
          className="menu menu-sub menu-sub-dropdown w-300px w-md-580px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "Models" ? "active" : ""
                  }`}
                  onClick={() => handleTabSwitch("Models")}
                >
                  Models
                </button>
              </li>
              {(canUseTheChief || hasAvatars) && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "Avatars" ? "active" : ""
                    }`}
                    onClick={() => handleTabSwitch("Avatars")}
                  >
                    Avatars
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="pb-7 px-5" data-kt-app-table-filter="form">
            {activeTab === "Models" ? (
              <ModelSelectionTableView
                userDocument={userDocument}
                onCompanyModelSelect={onCompanyModelSelect}
                isTheChiefFeatureAvailable={canUseTheChief}
              />
            ) : (
              <AvatarSelectionTableView
                userDocument={userDocument}
                onCompanyAvatarSelect={(avatar) => {
                  if (buttonRef.current) {
                    buttonRef.current.click();
                  }

                  onCompanyAvatarSelect(avatar);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);
