/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { UserDocument } from "../../app/models/UserDocument";

export default async function updateUserDocument(
  userDocument: UserDocument
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateUserDocument(
        id: ${userDocument.id}
        name: "${userDocument.name}"
        selected_apps: "${userDocument.selected_apps}"
      ) 
    }
  `;

  const updateUserDocumentMutationResult = await client.mutate({ mutation });

  const result = get(
    updateUserDocumentMutationResult,
    "data.updateUserDocument",
    null
  );

  return result !== null && result[0] > 0;
}
