import { gql } from "@apollo/client";
import { get } from "lodash";
import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyAvatar } from "../../app/models/CompanyAvatar";

export default async function getCompanyAvatars(
  company_id: number
): Promise<CompanyAvatar[]> {
  const client = ApolloClientHelper.getApolloClient();

  const query = gql`
    query CompanyAvatarsQuery($company_id: ID!) {
      companyAvatars(company_id: $company_id) {
        id
        company_id
        name
        description
        logo_url
        company_avatar_departments {
          id
          company_avatar_id
          department_id
          department {
            id
            name
          }
        }
        status
        last_sync_at
      }
    }
  `;

  try {
    const { data } = await client.query({
      query,
      variables: { company_id },
      fetchPolicy: "no-cache",
    });
    return get(data, "companyAvatars", []);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
