import React from "react";
import { CompanyUserStatus } from "../../../../../../helpers/Enums";

type Props = {
  status: string;
};

export class CompanyUserStatusCell extends React.Component<Props> {
  render() {
    const { status } = this.props;

    return (
      <div className="text-center">
        {status === CompanyUserStatus.Approved ? (
          <span className="badge badge-light-success fw-bolder">Approved</span>
        ) : status === CompanyUserStatus.InProgress ? (
          <span className="badge badge-light-warning fw-bolder">
            In Progress
          </span>
        ) : status === CompanyUserStatus.Declined ? (
          <span className="badge badge-light-danger fw-bolder">Declined</span>
        ) : null}
      </div>
    );
  }
}
